import React from 'react'
import PaintedEventSnackMessage from '../js/uicomponents/painted_event_notification'
import toast from 'react-hot-toast'

const defaultStyle = {
  maxWidth: '500px',
  wordBreak: 'break-word',
  background: '#333',
  color: '#fff'
}

export const success = (message, { duration = 2000 } = {}) => {
  return toast.success(message, { style: defaultStyle, duration })
}

export const error = (
  message,
  { duration = 2000, showRasterize = false } = {}
) => {
  return toast.error(message, { style: defaultStyle, showRasterize, duration })
}

export const warning = (message, { duration = 4000 } = {}) => {
  return toast(message, { icon: '⚠️', style: defaultStyle, duration })
}

export const info = (message, { duration = 4000 } = {}) => {
  return toast(message, { icon: 'ℹ️', style: defaultStyle, duration })
}

export const promise = (promiseFunction, messages, { duration = 0 } = {}) => {
  return toast.promise(
    promiseFunction,
    {
      loading: messages.loading,
      success: messages.success,
      error: messages.error
    },
    { style: defaultStyle, duration }
  )
}

export const paintedEvent = event => {
  return toast.custom(
    t => (
      <PaintedEventSnackMessage
        event={event}
        onClose={() => toast.dismiss(t.id)}
      />
    ),
    { position: 'bottom-right', duration: 6000 }
  )
}

export default { success, error, warning, info, promise, paintedEvent }
