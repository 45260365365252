import React, { forwardRef, useState } from 'react'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import { useRandomInterval } from '../../../lib/useRandomInterval'

function FlickerImage ({ className, srcOff, srcOn, style }, ref) {
  const [on, setOn] = useState(true)
  const [minDelay, setMinDelay] = useState(50)
  const [maxDelay, setMaxDelay] = useState(150)

  useRandomInterval(
    () => {
      let shouldTurnOn = Math.random() > 0.1

      setOn(shouldTurnOn)
      // if (shouldTurnOn) {
      //   setMinDelay(1500)
      //   setMaxDelay(2500)
      // } else {
      //   setMinDelay(50)
      //   setMaxDelay(300)
      // }
    },
    minDelay,
    maxDelay
  )

  return (
    <Box
      ref={ref}
      className={className}
      style={{
        position: 'relative'
      }}
    >
      <Image
        src={srcOff}
        style={{
          ...style,
          ...(!on && {
            display: 'block',
            zIndex: 1,
            opacity: 1
          }),
          ...(on && {
            display: 'hidden',
            opacity: 0
          })
        }}
      />

      <Image
        src={srcOn}
        style={{
          ...style,
          position: 'absolute',
          left: 0,
          top: 0,
          ...(on && {
            display: 'block',
            zIndex: 1,
            opacity: 1
          }),
          ...(!on && {
            display: 'hidden',
            opacity: 0
          })
        }}
      />
    </Box>
  )
}
const Image = styled.img`
  width: 100%;
  object-fit: contain;
`

export default forwardRef(FlickerImage)
