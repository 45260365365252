import React from 'react'
import Typography from '@mui/material/Typography'
import { Icon, Button } from '@mui/material'
import styled from 'styled-components'

const UniswapButton = ({ text, onClick, sx }) => {
  return (
    <RoundedButton
      variant='text'
      aria-label='close'
      onClick={onClick}
      sx={{
        ...sx,
        color: 'inherit',
        fontSize: 'inherit',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }}
      endIcon={
        <Icon style={{ fontSize: 28, textAlign: 'center' }}>
          <img src={'images/logos/uniswap_logo_white.svg'} alt={'uniswap'} />
        </Icon>
      }
    >
      <Typography variant='body1'>{text}</Typography>
    </RoundedButton>
  )
}

const RoundedButton = styled(Button)`
  border-radius: 20px;
  border: 1px solid;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
`

export default UniswapButton
