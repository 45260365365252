import config from './../../config.js'
import Base_layers_class from './../../core/base-layers.js'
import GUI_tools_class from './../../core/gui/gui-tools.js'
import Base_selection_class from './../../core/base-selection.js'
import Selection_class from './../../tools/selection.js'
import Helper_class from './../../libs/helpers.js'
import Dialog_class from './../../libs/popup.js'
import notification from '../../../lib/notification.js'
class Layer_new_class {
  constructor () {
    this.Base_layers = new Base_layers_class()
    this.POP = new Dialog_class()
    this.Selection = new Selection_class()
    this.Base_selection = new Base_selection_class(this.Base_layers.ctx)
    this.GUI_tools = new GUI_tools_class()
    this.Helper = new Helper_class()

    this.set_events()
  }

  set_events () {
    var _this = this

    document.addEventListener(
      'keydown',
      function (event) {
        var code = event.keyCode
        if (
          event.target.type == 'text' ||
          event.target.tagName == 'INPUT' ||
          event.target.type == 'textarea'
        )
          return

        if (code == 78 && event.ctrlKey != true && event.metaKey != true) {
          //N
          _this.new()
        }
      },
      false
    )
  }

  new () {
    window.State.save()
    this.Base_layers.insert()
  }

  new_selection () {
    var selection = this.Base_selection.get_selection()
    var layer = config.layer

    if (selection.width === null || config.layer.type != 'image') {
      notification.error('Empty selection or type not image.')
      return
    }
    if (config.TOOL.name != 'selection') {
      notification.error('Empty selection or type not image.')
      return
    }

    window.State.save()

    //if image was stretched
    var width_ratio = layer.width / layer.width_original
    var height_ratio = layer.height / layer.height_original

    var left = selection.x - layer.x
    var top = selection.y - layer.y

    //adapt to origin size
    selection.width = selection.width / width_ratio
    selection.height = selection.height / height_ratio

    //create new layer
    var canvas = document.createElement('canvas')
    var ctx = canvas.getContext('2d')
    canvas.width = Math.round(selection.width)
    canvas.height = Math.round(selection.height)

    ctx.translate(-left / width_ratio, -top / height_ratio)
    ctx.drawImage(config.layer.link, 0, 0)
    ctx.translate(0, 0)

    //register it
    var params = {
      x: Math.round(selection.x),
      y: Math.round(selection.y),
      width: Math.round(selection.width * width_ratio),
      height: Math.round(selection.height * height_ratio),
      width_original: Math.round(selection.width),
      height_original: Math.round(selection.height),
      type: 'image',
      data: canvas.toDataURL('image/png')
    }
    this.Base_layers.insert(params, false)

    this.Selection.on_leave()
    this.GUI_tools.activate_tool('select')
  }

  divide_layers () {
    var _this = this
    var settings = {
      title: 'Divide layer',
      params: [
        { name: 'rows', title: 'Rows:', value: '5' },
        { name: 'columns', title: 'Columns:', value: '4' }
      ],
      on_change: function (params, canvas_preview, w, h) {},
      on_finish: function (params) {
        _this.new_divide(params.rows, params.columns)
      }
    }
    this.POP.show(settings)
  }

  new_divide (rows, columns) {
    var layer = config.layer

    if (config.layer.type != 'image') {
      notification.error('Type not image.')
      return
    }

    if (rows == 0 || columns == 0) {
      notification.error('Must have at least 1 row or column')
      return
    }

    window.State.save()

    //if image was stretched
    var width_ratio = layer.width / layer.width_original
    var height_ratio = layer.height / layer.height_original

    var left = layer.x
    var top = layer.y

    //adapt to origin size
    var totalWidth = layer.width / width_ratio
    var totalHeight = layer.height / height_ratio

    var widthPerTile = Math.ceil(totalWidth / columns)
    var heightPerTile = Math.ceil(totalHeight / rows)

    var originalWidthPerTile = Math.ceil(layer.width_original / columns)
    var originalHeightPerTile = Math.ceil(layer.height_original / rows)

    var layerInfo = config.layer.link
    var layerName = config.layer.name

    for (let row = 0; row < rows; row++) {
      for (let column = 0; column < columns; column++) {
        var layerLeft = left + column * originalWidthPerTile
        var layerTop = top + row * originalHeightPerTile
        //create new layer
        var canvas = document.createElement('canvas')
        var ctx = canvas.getContext('2d')
        canvas.width = widthPerTile
        canvas.height = heightPerTile

        ctx.translate(
          -(column * originalWidthPerTile),
          -(row * originalHeightPerTile)
        )
        ctx.drawImage(layerInfo, 0, 0)
        ctx.translate(0, 0)
        var imageIndex = row * columns + column + 1
        //register it
        var params = {
          x: layerLeft,
          y: layerTop,
          width: widthPerTile * width_ratio,
          height: heightPerTile * height_ratio,
          width_original: widthPerTile,
          height_original: heightPerTile,
          name: '#' + imageIndex + ' ' + layerName,
          type: 'image',
          data: canvas.toDataURL('image/png')
        }
        this.Base_layers.insert(params, false)
      }
    }
  }
}

export default Layer_new_class
