import React from 'react'
import Typography from '@mui/material/Typography'

const TermAndConditions = () => {
  return (
    <Typography
      variant='body1'
      component='p'
      align='justify'
    >
      The Grid is a decentralized, peer to peer protocol for user-created artwork.
      The Grid is free, public, open source software and a set of smart contracts
      written in Solidity that can be deployed to the Ethereum Blockchain,
      collectively known as (the Grid Protocol”). Before using the Grid
      Protocol, please review the FAQs and documentation (the “Documentation”)
      for a detailed explanation on how the Grid Protocol works.
      <br />
      <br />
      MurAll (the “Foundation”, “we,” “us,” or “our”) is a
      not-for-profit entities. It does not own or lead MurAll, but rather supports
      and develops the free, open-source protocol that is The Grid. Portions of
      the Grid Protocol are made available under the GNU General Public
      License v3 -- and the disclaimers contained therein apply including that
      the Grid Protocol is provided “AS IS”, “WITH ALL FAULTS” and “AT YOUR
      OWN RISK”. We will not be liable for any liability or damages whatsoever
      associated with your use, inability to use, or your interaction with other
      users of, the Grid Protocol, including any direct, indirect, incidental,
      special, exemplary, punitive or consequential damages, loss of profits,
      Ether (ETH) tokens, and any other cryptocurrencies or anything of value,
      including fiat currency.
      <br />
      <br />
      You acknowledge that the current version of the Grid Protocol is a beta
      version and as such has not been fully-tested and may not perform as
      designed. On the Grid Protocol, Ether (ETH) and Paint (PAINT), a
      cryptographic token, may be used for producing artwork on the Grid
      Protocol. While you should always be thoughtful about the ETH or other
      tokens/cryptocurrencies you use (and can lose) through the Grid
      Protocol, the concerns regarding loss of these tokens or cryptocurrencies
      is particularly acute with beta software that may not perform as designed,
      including that the beta version of the Grid Protocol may not accurately
      reflect the intent of the smart contracts, the FAQs or the Documentation.
      Your use of the Grid Protocol involves various risks, including, but not
      limited to losing tokens/cryptocurrencies due to invalidation. Careful due
      diligence should be undertaken as to the amount of ETH or PAINT you
      use/spend using the Grid Protocol in beta format with full understanding
      that any transactions using these tokens/cryptocurrencies while drawing on
      Grid Protocol could be subject to total loss. You assume any and all
      risk associated with your use of the Grid Protocol.
      <br />
      <br />
      PAINT may be listed or delisted on various possible exchanges.
      We disavow any obligation with respect to the listing of PAINT on
      exchanges and it disavows any responsibility with respect to the value or
      trading of PAINT on exchanges. Persons trading PAINT or otherwise engaged
      in activities involving PAINT on exchanges assume any and all risk,
      including that of total loss, associated with such activities.
      <br />
      <br />
      You are solely responsible for compliance with all laws that may apply to
      your particular use of the Grid Protocol. Cryptocurrencies and
      blockchain technologies have been the subject of scrutiny by various
      regulatory bodies around the world. We make no representation regarding
      the application of any laws, including but by no means limited to those
      relating to gaming, options, derivatives or securities, to your use of the
      Grid Protocol. Indeed, depending on the jurisdiction and the
      contemplated use of the Grid Protocol (whether yours or another’s), that
      use may be considered illegal. You agree that the We or any other referrer
      is not responsible for determining whether or which laws may apply to your
      use of the Grid Protocol. You may modify the Grid Protocol under the
      terms of the applicable open source license to effectuate your compliance
      with any applicable laws.
      <br />
      <br />
      The products and services, (collectively, the “Services”) provided by us
      may contain information, text, graphics, photos, or other materials
      (“Content”), including Content created or submitted to the Services by you
      or through use of the Grid Protocol. We take no responsibility for and
      we do not expressly or implicitly endorse, support, or guarantee the
      completeness, truthfulness, accuracy, or reliability of any of the
      Content.
      <br />
      <br />
      By submitting Content to the Grid Protocol, you represent and warrant
      that you have all rights, power, and authority necessary to grant the
      rights to Your Content contained within these Terms. Because you alone are
      responsible for Your Content, you may expose yourself to liability if you
      upload or share Content without all necessary rights.
      <br />
      <br />
      You retain any ownership rights you have in Your Content, but you grant us
      the following license to use that Content:
      <br />
      <br />
      By submitting, posting or displaying Content on or through the Services,
      you grant us a worldwide, royalty-free, perpetual, irrevocable,
      non-exclusive, transferable, and sublicensable license to use, copy,
      reproduce, process, adapt, modify, publish, transmit, display and
      distribute such Content in any and all media or distribution methods (now
      known or later developed). This license authorizes us to make your Content
      available to the rest of the world and to let others do the same. You
      agree that this license includes the right for us to provide, promote, and
      improve the Services and to make Content submitted to or through the
      Services available to other companies, organizations or individuals for
      the distribution, promotion or publication of such Content on other media
      and services. Such additional uses by us, or other companies,
      organizations or individuals, may be made with no compensation paid to you
      with respect to the Content that you submit, post, transmit or otherwise
      make available through the Services.
      <br />
      <br />
      All content displayed on the Services is created by users of the Grid
      Protocol, and due to the decentralized nature of the Grid Protocol, is
      not under our control, and thus may contain content that users deem
      explicit, profane, and/or offensive, and by using the Services you
      acknowledge this risk and accept sole responsibility for all that you see
      and interact with while using the Services. The Foundation makes no
      representations or warranties as to the quality, origin, or ownership of
      any content found in the Services, and all uses thereof, and the Grid
      Protocol. We require all users to be 18 years old or older. You will
      immediately cease and not continue to access the site if you are under the
      age of 18.
      <br />
      IN NO EVENT AND UNDER NO THEORY OF LIABILITY, INCLUDING CONTRACT, TORT,
      NEGLIGENCE, STRICT LIABILITY, WARRANTY, OR OTHERWISE, WILL THE FOUNDATION
      BE LIABLE TO YOU FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
      SPECIAL, OR PUNITIVE DAMAGES, OR LOST PROFITS ARISING FROM OR RELATING TO
      THESE TERMS OR THE SERVICES, INCLUDING THOSE ARISING FROM OR RELATING TO
      CONTENT MADE AVAILABLE ON THE SERVICES THAT IS ALLEGED TO BE DEFAMATORY,
      OFFENSIVE, OR ILLEGAL. ACCESS TO, AND USE OF, THE SERVICES IS AT YOUR OWN
      DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ALL YOU SEE
      AND INTERACT WITH WHILE USING THE SERVICES.
      <br />
      <br />
      We shall not be liable for any errors, misrepresentations, or omissions
      in, of, and about, the content, nor for the availability of the content.
      We shall not be liable for any losses, injuries, or damages from the
      purchase, inability to purchase, display, or use of content.
      <br />
      <br />
    </Typography>
  )
}

export default TermAndConditions
