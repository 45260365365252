import { useMemo } from 'react'
import { useActiveWeb3React } from './web3'
import { ethers } from 'ethers'

export const useEthersSigner = () => {
  const { provider, account, isActive } = useActiveWeb3React()

  return useMemo(() => {
    let signer = null
    if (provider && account && isActive) {
      signer = new ethers.providers.Web3Provider(provider.provider).getSigner()
    }

    return signer
  }, [provider, account, isActive])
}
