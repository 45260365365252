import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles'
import AboutGridImage from '../../../images/logos/GRID_GRID.png'
import AboutGridOffImage from '../../../images/logos/GRID_GRID_OFF.png'
import PaintIconImage from '../../../images/logos/GRID_PAINT.png'
import PaintOffIconImage from '../../../images/logos/GRID_PAINT_OFF.png'
import AboutNftImage from '../../../images/logos/GRID_NFT.png'
import AboutNftOffImage from '../../../images/logos/GRID_NFT_OFF.png'
import OnChainImage from '../../../images/100_percent_onchain.png'
import '../../css/layout.css'
import clsx from 'clsx'
import { Grid, Typography, Box, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Element as ScrollElement } from 'react-scroll'
import FlickerImage from './images/flicker-image'
import Fade from '../../reveal/in-and-out/Fade'

const MAX_WIDTH = 1200

const useStyles = makeStyles((theme) => ({
  gradientBackground: {
    background:
      'radial-gradient(circle at top right,' +
      theme.palette.secondary.main +
      '40' +
      ' 45%, ' +
      theme.palette.primary.main +
      '80' +
      ')',
  },
  bgImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundBlendMode: `multiply, normal`,
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
  },
  bgImageTile: {
    backgroundPosition: 'center center',
    backgroundBlendMode: `multiply, normal`,
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'repeat',
  },
}))

const AboutContents = ({ detailsRef }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <Grid
        className={clsx('slanted_top', classes.bgImage)}
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        sx={{
          backgroundColor: '#00000080',
          backdropFilter: 'blur(3px)',
        }}
      >
        <Grid
          ref={detailsRef}
          className={clsx('slanted', classes.bgImage)}
          item
          xs={12}
          p={{ xs: 5, sm: 10 }}
          style={{
            backgroundImage: backgroundForImage('images/wood_bg.jpeg'),
          }}
        >
          <DefaultContainer>
            <Grid
              container
              direction={isMobile ? 'column' : 'row'}
              spacing={1}
              alignItems={'center'}
            >
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Fade left>
                  <FlickerImage
                    srcOff={AboutGridOffImage}
                    srcOn={AboutGridImage}
                  />
                </Fade>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <Fade top>
                  <ScrollElement name="details">
                    <Title>What is the Supporter Grid?</Title>
                  </ScrollElement>
                </Fade>
                <Fade top>
                  <Paragraph>
                    Join the Supporter Cult, and get access to draw on the{' '}
                    <Typography
                      variant="h6"
                      display="inline"
                      component="p"
                      sx={{
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 300,
                        textShadow: '2px 2px 5px black',
                        textDecoration: 'line-through',
                      }}
                    >
                      Maintenance Closet Door
                    </Typography>
                    <Typography
                      variant="h6"
                      display="inline"
                      component="p"
                      sx={{
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 300,
                        textShadow: '2px 2px 5px black',
                      }}
                    >
                      {' '}
                      super prominently fancy display in the Yacht Club, and
                      sort-of smack-dab-in-the-center of our website (as well as
                      other cool benefits). Shill your wares, and/or show your
                      support for getting Boring Security out of the club
                      basement and into an office with a working toilet!
                    </Typography>
                  </Paragraph>
                </Fade>
              </Grid>
            </Grid>
          </DefaultContainer>
        </Grid>

        <Grid item xs={12} p={{ xs: 5, sm: 10 }}>
          <DefaultContainer>
            <Grid
              container
              direction={isMobile ? 'column' : 'row'}
              spacing={1}
              alignItems={'center'}
            >
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <Fade top>
                  <Title>Your NFT</Title>
                </Fade>
                <Fade top>
                  <Paragraph>
                    Your NFT gets you access to ONE square on the supporter grid{' '}
                    <Link
                      component={RouterLink}
                      underline="none"
                      to="/mint"
                      color="secondary"
                    >
                      (and many other benefits!)
                    </Link>
                    . Stored completely on-chain and 160x160 pixels in true
                    pixel-art style so that the penises you draw are naturally
                    censored! With only 69 planning to be sold, I bet you think
                    you're pretty special for buying one eh? Well we certainly
                    do!
                    <br />
                    <br />
                    Fancy sharing the fun? Enable "Free For All" mode in your
                    NFT to allow Boring Security 101 NFT holders to draw in your
                    portion of The Grid!
                  </Paragraph>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Fade right>
                  <FlickerImage
                    srcOff={AboutNftOffImage}
                    srcOn={AboutNftImage}
                  />
                </Fade>
              </Grid>
            </Grid>
          </DefaultContainer>
        </Grid>
        <Grid
          className={clsx('slanted', classes.bgImage)}
          item
          xs={12}
          p={{ xs: 5, sm: 10 }}
          style={{
            backgroundImage: darkerBackgroundForImage(
              'images/cabin_graffiti_ape_extended_compressed.png'
            ),
            backgroundPosition: 'center center',
          }}
        >
          <DefaultContainer>
            <Grid
              container
              direction={isMobile ? 'column' : 'row'}
              spacing={1}
              alignItems={'center'}
            >
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Fade left>
                  <FlickerImage
                    srcOff={PaintOffIconImage}
                    srcOn={PaintIconImage}
                  />
                </Fade>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <Fade top>
                  <Title>Powered by Paint</Title>
                </Fade>
                <Fade top>
                  <Paragraph>
                    The Grid requires a digital token called PAINT (the same
                    token the MurAll requires) to enable artists to draw onto
                    their portion of The Grid. To cover your 160 x 160 pixel
                    grid portion costs 1 PAINT per pixel totalling 25600 PAINT
                    per coverage, and just like real life paint once used it
                    can’t be taken off of the wall and reused! With only a
                    finite amount of PAINT available globally it is a commodity
                    which encourages artists to produce work they are passionate
                    about as the more PAINT that is used, the less is available!
                  </Paragraph>
                </Fade>
              </Grid>
            </Grid>
          </DefaultContainer>
        </Grid>

        <Grid item xs={12} p={{ xs: 5, sm: 10 }}>
          <DefaultContainer>
            <Grid
              container
              direction={isMobile ? 'column' : 'row'}
              spacing={1}
              alignItems={'center'}
            >
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <Fade top>
                  <Title>All Artwork On Chain</Title>
                </Fade>
                <Fade top>
                  <Paragraph>
                    Using our proprietary Blockmap image encoding, all artwork
                    drawn on The Grid is put onto the blockchain itself, the
                    worlds most secure and robust storage availble, ensuring
                    your artwork will be available for as long as the blockchain
                    lives.
                  </Paragraph>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Fade right>
                  <Image src={OnChainImage} />
                </Fade>
              </Grid>
            </Grid>
          </DefaultContainer>
        </Grid>
      </Grid>
    </>
  )
}

const Title = (props) => (
  <Typography
    {...props}
    variant="h3"
    component="h3"
    align="center"
    gutterBottom
    my={2}
    sx={{
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 100,
      textShadow: '2px 2px 5px black',
    }}
  >
    {props.children}
  </Typography>
)
const Paragraph = ({ children }) => (
  <Typography
    variant="h6"
    align="justify"
    component="p"
    gutterBottom
    sx={{
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      textShadow: '2px 2px 5px black',
    }}
  >
    {children}
  </Typography>
)

function DefaultContainer(props) {
  return (
    <Box
      {...props}
      sx={{
        ...(props.sx
          ? {
              ...props.sx,
            }
          : {}),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: `${MAX_WIDTH}px`,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        padding: 0,
        position: 'relative',
      }}
    >
      {props.children}
    </Box>
  )
}

const backgroundForImage = (image, radialGradientPlacement = `right`) => {
  return `radial-gradient(circle at top ${radialGradientPlacement},
    #cccccc 25%,
    #4a4a4a,
    #212121),
    url(${image})`
}

const darkerBackgroundForImage = (image) => {
  return `linear-gradient(0deg,
      #4a4a4a,
      #4a4a4a),
      url(${image})`
}

const Image = styled.img`
  width: 100%;
  object-fit: contain;
`

export default AboutContents
