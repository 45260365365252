import React, { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import CardHeader from '@mui/material/CardHeader'
import Card from '@mui/material/Card'
import Skeleton from '@mui/material/Skeleton'
import _ from 'underscore'
import { CardActionArea, Avatar, CardMedia } from '@mui/material'
import { useMurAllFramesDataSource } from '../../../hooks/use-murall-frame-datasource'
import clsx from 'clsx'
import { replaceIpfsIfNeeded } from '../../../js/libs/appUtils'
import useMediaQuery from '@mui/material/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    border: 'solid',
    borderColor: 'black',
    borderWidth: '1px',
  },
  selected: {
    border: 'solid',
    borderColor: theme.palette.secondary.main,
    borderWidth: '1px',
    boxShadow: '0px 0px 20px 3px ' + theme.palette.primary.main,
  },
  media: {
    backgroundColor: theme.palette.primary.dark,
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '100%', // square
  },
}))

export default function FrameGridItem({ title, tokenId, onClick, selected }) {
  const classes = useStyles()

  const { getFullTokenUriMetadata } = useMurAllFramesDataSource()
  const [isFetching, setIsFetching] = useState(false)
  const [tokenUriMetadata, setTokenUriMetadata] = useState(null)
  const [image, setImage] = useState(null)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  useEffect(() => {
    if (tokenId) {
      setIsFetching(true)
      getFullTokenUriMetadata(tokenId).then((metadata) => {
        console.log('metadata for ' + tokenId, metadata)
        if (metadata.image) {
          const image = replaceIpfsIfNeeded(metadata.image)
          console.log('image', image)
          setImage(image)
        }
        setTokenUriMetadata(metadata)
        setIsFetching(false)
      })
    }
  }, [tokenId])

  return (
    <Card
      className={clsx(classes.root, {
        [classes.selected]: selected,
      })}
      elevation={0}
    >
      <CardActionArea onClick={onClick}>
        <CardHeader
          avatar={
            <Avatar
              src={'images/logos/FRAME_small.png'}
              variant="square"
              {...(isMobile && { sx: { width: 16, height: 16 } })}
              {...(!isMobile && { sx: { width: 32, height: 32 } })}
            />
          }
          title={title ? title : <Skeleton variant="text" />}
          titleTypographyProps={{
            align: 'left',
            variant: isMobile ? 'body1' : 'h5',
            component: isMobile ? 'body1' : 'h5',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 300,
          }}
          sx={{ padding: isMobile ? '0.5em' : '1em' }}
        />
        {isFetching || !tokenId || !image ? (
          <Skeleton className={clsx(classes.media)} variant="rectangular" />
        ) : (
          <CardMedia
            className={clsx(classes.media)}
            image={image}
            title={title}
            alt={title}
          ></CardMedia>
        )}
      </CardActionArea>
    </Card>
  )
}
