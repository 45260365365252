//main config file

var config = {}

config.TRANSPARENCY = false
config.TRANSPARENCY_TYPE = 'squares' //squares, green, grey
config.LANG = 'en'
config.WIDTH = 160
config.HEIGHT = 160
config.visible_width = null
config.visible_height = null
config.COLOR = '#FFFFFF'
config.ALPHA = 255
config.alphaEnabled = false
config.ZOOM = 1
config.pixabay_key = '3ca2cd8af3fde33af218bea02-9021417'
config.layers = []
config.layer = null
config.need_render = false
config.mouse = {}
config.blockchainDataLayer = null

//requires styles in reset.css
config.themes = ['dark', 'l2']

config.TOOLS = [
  {
    name: 'select',
    title: 'Select object tool',
    attributes: {
      auto_select: true
    }
  },
  {
    name: 'selection',
    title: 'Selection',
    attributes: {},
    on_leave: 'on_leave'
  },
  {
    name: 'pick_color',
    title: 'Pick Color',
    attributes: {
      global: false
    }
  },
  {
    name: 'text',
    title: 'Text',
    attributes: {}
  },

  {
    name: 'pencil',
    title: 'Pencil',
    on_update: 'on_params_update',
    attributes: {
      antialiasing: true,
      size: 2
    }
  },
  {
    name: 'brush',
    title: 'Brush',
    attributes: {
      size: 4,
      smart_brush: true
    }
  },
  {
    name: 'erase',
    title: 'Erase',
    on_update: 'on_params_update',
    attributes: {
      size: 30,
      circle: true,
      strict: true
    }
  },
  {
    name: 'magic_wand',
    title: 'Smart erase',
    attributes: {
      power: 15,
      anti_aliasing: true,
      contiguous: false
    }
  },
  {
    name: 'fill',
    title: 'Fill',
    attributes: {
      power: 5,
      anti_aliasing: false,
      contiguous: false
    }
  },
  {
    name: 'clone',
    title: 'Clone tool',
    attributes: {
      size: 30,
      anti_aliasing: true,
      source_layer: {
        value: 'Current',
        values: ['Current', 'Previous']
      }
    }
  },
  {
    name: 'palette256',
    title: 'Reduce Palette to 256',
    on_activate: 'on_activate',
    attributes: {}
  },
  {
    name: 'rasterize',
    title: 'Rasterize',
    on_activate: 'on_activate',
    attributes: {}
  },
  {
    name: 'line',
    title: 'Line',
    attributes: {
      size: 1,
      type: {
        value: 'Simple',
        values: ['Simple', 'Arrow']
      }
    }
  },
  {
    name: 'rectangle',
    title: 'Rectangle',
    attributes: {
      size: 1,
      radius: 0,
      fill: true,
      square: false
    }
  },
  {
    name: 'circle',
    title: 'Circle',
    attributes: {
      size: 1,
      fill: true,
      circle: false
    }
  }
]

//link to active tool
config.TOOL = config.TOOLS[0]

export default config
