import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import AlchemyLogo from './alchemy-logo'
import PulseBorder from '../pulse-border'

import PoweredByMurAllView from '../powered-by-murall-view'
import BoringSecurityClickThrough from '../boring-security-clickthrough'

const Footer = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isLandscape = useMediaQuery('(orientation: landscape)')
  return (
    <footer>
      <Border />
      <Box
        sx={{
          pt: 1.5,
          pb: 1.5,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          backgroundColor: '#000000B3',
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            rowSpacing={isMobile ? 1 : 2}
            columnSpacing={{ xs: 2, sm: 2, md: 3 }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={isMobile && isLandscape ? 4 : 12} sm={4}>
              <PoweredByMurAllView />
            </Grid>
            <Grid item xs={isMobile && isLandscape ? 4 : 12} sm={4}>
              <BoringSecurityClickThrough />
            </Grid>
            <Grid
              item
              xs={isMobile && isLandscape ? 4 : 12}
              sm={4}
              textAlign="center"
            >
              <AlchemyLogo />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  )
}

const Border = styled(PulseBorder)`
  bottom: auto;
`

export default Footer
