import config from './../../config.js'
import Base_layers_class from './../../core/base-layers.js'
import Dialog_class from './../../libs/popup.js'
import Helper_class from './../../libs/helpers.js'
import Worker from './../../workers/color/reduceCanvasTo256Color.worker.js'
import notification from '../../../lib/notification.js'

class Image_reduce_to_256_colors_class {
  constructor () {
    this.POP = new Dialog_class()
    this.Base_layers = new Base_layers_class()
    this.Helper = new Helper_class()
    this.worker = new Worker()
  }

  convert_to_256_color () {
    var _this = this
    if (config.layer == undefined) {
      return
    }

    if (config.layer.type != 'image') {
      notification.error(
        'Layer must be image, convert it to raster to apply this tool.',
        { showRasterize: true }
      )
      return
    }

    var modes = [
      'FloydSteinberg',
      'FalseFloydSteinberg',
      'Stucki',
      'Atkinson',
      'Jarvis',
      'Burkes',
      'Sierra',
      'TwoSierra',
      'SierraLite'
    ]

    var settings = {
      title: 'Convert to 256 color depth',
      preview: false,
      params: [
        { name: 'dithKern', title: 'Dither mode:', values: modes },
        { name: 'dithSerp', title: 'Serpentine:', value: true }
      ],
      on_finish: function (params) {
        window.State.save()
        _this.execute(params)
      }
    }
    this.POP.show(settings)
  }

  execute (params) {
    this.quantizeCanvasImage(params)
  }

  async quantizeCanvasImage (params) {
    // set loading
    this.setLoadingVisible(true)

    // get canvas
    var canvas = this.Base_layers.convert_layer_to_canvas(null, false, true)
    var ctx = canvas.getContext('2d')
    this.disable_canvas_smooth(ctx)

    window.State.save()

    // create params for the new layer
    var layerParams = {
      type: 'image',
      name: '256 Color ' + config.layer.name,
      data:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
      x: config.layer.x,
      y: config.layer.y,
      width: canvas.width,
      height: canvas.height,
      opacity: config.layer.opacity,
      alphaEnabled: config.layer.alphaEnabled
    }

    //create the new layer from these params
    await this.Base_layers.insert(layerParams, false)

    var _this = this
    // setup rgb quant
    this.worker.onmessage = function (msg) {
      var buf = msg.data.canvasBuffer
      var arr = new Uint8ClampedArray(buf)
      let processedImageData
      try {
        processedImageData = new ImageData(
          arr,
          imageData.width,
          imageData.height
        )
      } catch (e) {
        processedImageData = ctx.createImageData(
          imageData.width,
          imageData.height
        )
        processedImageData.data.set(arr)
      }

      ctx.putImageData(processedImageData, 0, 0)

      _this.Base_layers.update_layer_image(canvas)
      _this.setLoadingVisible(false)
    }

    // reduce images
    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    var buffers = [imageData.data.buffer]
    var message = {
      canvasBuffer: imageData.data.buffer,
      dithKern: params.dithKern,
      dithSerp: params.dithSerp,
      alphaEnabled: config.layer.alphaEnabled,
      width: canvas.width,
      height: canvas.height,
      buffers: buffers,
      isLittleEndian: this.Helper.isLittleEndian()
    }

    this.worker.postMessage(message, buffers)
  }

  setLoadingVisible (visible) {
    this.Helper.dispatchCustomEvent(
      'drawingContainer',
      'changeLoadingVisibility',
      {
        showLoading: visible
      }
    )
  }

  /**
   * removes smoothing, because it look ugly during zoom
   *
   * @param {ctx} ctx
   */
  disable_canvas_smooth (ctx) {
    ctx.webkitImageSmoothingEnabled = false
    ctx.oImageSmoothingEnabled = false
    ctx.msImageSmoothingEnabled = false
    ctx.imageSmoothingEnabled = false
  }
}

export default Image_reduce_to_256_colors_class
