import React from 'react'
import LoadingSpinner from './loading_spinner'
import Dialog from '@mui/material/Dialog'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  backDrop: {
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(83, 6, 152, 0.2)',
    '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
      backgroundColor: 'rgba(0,0,30,0.85)'
    }
  }
}))

export default function LoadingDialog (props) {
  const classes = useStyles()
  return (
    <Dialog
      fullScreen={true}
      disableEscapeKeyDown={true}
      open={props.open}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
    >
      <LoadingSpinner />
    </Dialog>
  )
}
