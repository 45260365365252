import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, matchPath } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'

import { getDateInFuture } from '../../../../lib/datetime'
import { setAgreed } from '../../../../state/slices/terms'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TransparentDialog from '../transparent'
import TermAndConditions from './terms'

const PATHS_WITH_TERMS = ['/draw', '/state']

const LegalDisclaimerDialog = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const [cookies, setCookie] = useCookies(['agreedToTerms'])

  const [show, setShow] = useState(false)
  const [remember, setRemember] = useState(false)

  const terms = useSelector(state => state.terms)

  const toggleRemember = event => setRemember(event.target.checked)

  const onAgree = () => {
    if (remember) {
      setCookie('agreedToTerms', true, {
        path: '/',
        expires: getDateInFuture(180)
      })
    }
    dispatch(setAgreed(true))
    setShow(false)
  }

  const onDisagree = () => history.push('/faq')

  useEffect(() => {
    if (!pathname) return
    const shouldCheckTerms = PATHS_WITH_TERMS.some(path =>
      matchPath(pathname, { path, exact: true })
    )
    const hasAgreed = !!cookies.agreedToTerms || terms.agreed
    setShow(shouldCheckTerms && !hasAgreed)
  }, [pathname])

  return (
    <TransparentDialog
      disableEscapeKeyDown
      open={show}
      showLogo
      title='Legal Disclaimer'
      dialogContent={<TermAndConditions />}
      footerContent={
        <FormControlLabel
          style={{ flex: 1 }}
          control={
            <Checkbox
              checked={remember}
              onChange={toggleRemember}
              name='rememberChoice'
            />
          }
          label='Remember my choice'
        />
      }
      okTitle='I agree to the above'
      onOk={onAgree}
      cancelTitle='Get me out of here'
      onCancel={onDisagree}
    />
  )
}

export default LegalDisclaimerDialog
