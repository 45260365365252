import { useEffect, useState } from 'react'

import { SupportedChain } from '../../lib/constants.js'
import { useEthersContractForAddress } from '../use-ethers-contract.js'
import GridNFTAbiL1 from '../../contracts/layer1/GridNFT.json'

const useListenForPaintedEvents = ({ active = true }) => {
  const [paintedEvents, setPaintedEvents] = useState(undefined)

  const contractInstance = useEthersContractForAddress({
    abi: GridNFTAbiL1.abi,
    websocketInstance: true,
    contractAddress: GridNFTAbiL1.networks[SupportedChain.Ethereum].address,
    targetChainId: SupportedChain.Ethereum
  })

  useEffect(() => {
    console.log(
      `useListenForPaintedEvents for Boring Security Grid - paintedEvents updated`,
      paintedEvents
    )
  }, [paintedEvents])

  useEffect(() => {
    if (!active) return
    if (!contractInstance) return

    const filter = contractInstance.filters.Painted()

    const listener = (...args) => {
      const event = args[args.length - 1]
      console.log(
        `useListenForPaintedEvents for Boring Security Grid - listener received event`,
        event
      )

      setPaintedEvents(rs => [event, ...(rs || [])])
    }

    const subscribe = async () => {
      contractInstance.on(filter, listener)
    }

    subscribe()
    return () => contractInstance.off(filter, listener)
  }, [contractInstance, active])

  return paintedEvents
}

export default useListenForPaintedEvents
