import GridNFTContract from './../../../../contracts/layer1/GridNFT.json'
import PaintTokenContract from './../../../../contracts/layer1/PaintToken.json'
import MurAllFrame from './../../../../contracts/layer1/MurAllFrame.json'
import PaintTokenContractL2 from './../../../../contracts/layer2/PaintToken.json'

const L1_NETWORK_ID_TESTNET = 5
const L1_NETWORK_ID_MAINNET = 1
const L1_NETWORK_ID_DEFAULT = L1_NETWORK_ID_MAINNET
const L1_L2_BRIDGE_ADDRESS_TESTNET =
  '0xdD6596F2029e6233DEFfaCa316e6A95217d4Dc34'
const L1_L2_BRIDGE_ADDRESS_MAINNET =
  '0x40ec5B33f54e0E8A33A975908C5BA1c14e5BbbDf'
const L1_L2_BRIDGE_ADDRESS_DEFAULT = L1_L2_BRIDGE_ADDRESS_MAINNET

const L2_NETWORK_ID_TESTNET = 80001
const L2_NETWORK_ID_MAINNET = 137
const L2_NETWORK_ID_DEFAULT = L2_NETWORK_ID_MAINNET

/**
 * Source for Layer 1 contract address data
 *
 * @author TheKeiron
 */

export const getGridNFTContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  const contract = GridNFTContract
  return (
    contract &&
    contract.networks[networkId] &&
    contract.networks[networkId].address
  )
}

export const getPaintTokenContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  const contract =
    networkId === L1_NETWORK_ID_MAINNET
      ? PaintTokenContract
      : PaintTokenContractL2
  return contract.networks[networkId]
    ? contract.networks[networkId].address
    : ''
}

export const getMurAllFramesContractAddress = (
  networkId = L1_NETWORK_ID_DEFAULT
) => {
  return MurAllFrame.networks[networkId].address
}
