import React from 'react'
import PaintImageIcon from '../../../../images/paint_logo.png'
import AddIcon from '@mui/icons-material/Add'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'

export const PaintIcon = () => {
  return (
    <Avatar
      sx={{ width: 16, height: 16 }}
      alt={'Paint token logo'}
      src={PaintImageIcon}
    />
  )
}

export const PaintPlusIcon = () => {
  return (
    <Badge
      overlap='circular'
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      badgeContent={
        <Avatar
          sx={{
            width: 8,
            height: 8,
            background: 'transparent',
            color: 'white'
          }}
        >
          <AddIcon />
        </Avatar>
      }
    >
      <Avatar
        sx={{ width: 20, height: 20 }}
        alt={'Paint token logo'}
        src={PaintImageIcon}
      />
    </Badge>
  )
}
