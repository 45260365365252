import { createSlice } from '@reduxjs/toolkit'
import { SUPPORTED_CHAINS, L2_CHAINS } from '../../lib/constants'

export const initialState = {
  id: undefined,
  loading: false,
  isLayer2: false,
  supported: false,
  forced: false
}

const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = !!action.payload
      return state
    },
    setNetwork: (state, action) => {
      const { id, forced } = action.payload
      const supported = SUPPORTED_CHAINS.includes(id)
      return { ...state, id: id || 1, supported, forced: !!forced }
    }
  }
})

export const { setLoading } = networkSlice.actions
export const { setNetwork } = networkSlice.actions

export default networkSlice.reducer
