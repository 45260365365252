import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import IconButton from '@mui/material/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ExternalLink from '../../common/links/external'
import IconLink from '../../common/links/icon'
import FramePickerDialog from '../../common/frames/frame-picker-dialog'
import { Icon, Divider } from '@mui/material'
import MurAllSocials from '../../common/socials/MurAllSocials'
import BoringSecuritySocials from '../../common/socials/BoringSecuritySocials'
import PulseBorder from '../../common/pulse-border'

const ExtraMenu = () => {
  const { account } = useWeb3React()
  const [anchorEl, setAnchorEl] = useState(null)

  const [framePickerOpen, setFramePickerOpen] = useState(false)

  const open = Boolean(anchorEl)

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <>
      <ExtraButton aria-label="extra-menu" onClick={handleClick}>
        <MoreHorizIcon />
      </ExtraButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 1.5,
            bgcolor: '#333',
            borderRadius: '10px',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#333',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        {account && (
          <MenuItem onClick={handleClose}>
            <Link
              to="/nfts"
              text="My NFTs"
              icon={
                <AccountBoxIcon
                  fontSize="inherit"
                  sx={{ marginLeft: 'auto' }}
                />
              }
            />
          </MenuItem>
        )}

        <MenuItem onClick={handleClose}>
          <ExternalLinkFullWidth
            href="https://opensea.io/collection/boring-security-supporter-grid"
            target="_blank"
            rel="noopener"
            text="View on Opensea"
            icon={
              <Icon
                style={{ fontSize: 'inherit', textAlign: 'center' }}
                sx={{ marginLeft: 'auto' }}
              >
                <img src={'images/logos/opensea.svg'} alt={'opensea'} />
              </Icon>
            }
          />
        </MenuItem>

        {/* {account && (
          <MenuItem
            onClick={() => {
              handleClose()
              setBridgeDialogOpen(true)
            }}
          >
            <Link
              text='Token Bridge'
              icon={
                <CompareArrowsIcon
                  fontSize='inherit'
                  sx={{ marginLeft: 'auto' }}
                />
              }
            />
          </MenuItem>
        )} */}

        {account && (
          <MenuItem
            onClick={() => {
              handleClose()
              setFramePickerOpen(true)
            }}
          >
            <Link
              text="Use Frames with The Grid"
              icon={
                <img
                  src={'images/logos/frames.png'}
                  alt={'use frames with the grid'}
                  style={{
                    marginLeft: 'auto',
                    height: 25,
                    width: 25,
                    objectPosition: 'center',
                    objectFit: 'contain',
                  }}
                />
              }
            />
          </MenuItem>
        )}
        <Border
          style={{
            marginTop: '1em',
            marginBottom: '1em',
          }}
        />
        <MurAllSocials sx={{ mt: 2 }} />
        <Divider variant="middle" sx={{ mt: 0.75 }} />
        <BoringSecuritySocials sx={{ mt: 1.5 }} />
      </Menu>
      {account && (
        <FramePickerDialog
          open={framePickerOpen}
          onClose={() => {
            setFramePickerOpen(false)
          }}
        />
      )}
    </>
  )
}

const ExtraButton = styled(IconButton)`
  background-color: #333;
  border-radius: 30%;
  padding: 0 5px;
  border: 1px solid #444;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`

const Link = styled(IconLink)`
  width: 100%;
`

const ExternalLinkFullWidth = styled(ExternalLink)`
  width: 100%;
`
const Border = styled(PulseBorder)`
  height: 1px;
  position: relative;
`

export default ExtraMenu
