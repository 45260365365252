import notification from '../../../lib/notification.js'
import Helper_class from './../../libs/helpers.js'

class Edit_paste_class {
  constructor () {
    this.Helper = new Helper_class()
  }

  paste () {
    notification.error('Use Ctrl+V keyboard shortcut to paste from Clipboard.')
  }
}

export default Edit_paste_class
