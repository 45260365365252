import React from 'react'
import { Typography, Button, Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import StyledImageViewerDialog from '../../uicomponents/styled_image_viewer_dialog'
import { red } from '@mui/material/colors'
import useSmallScreenDetection from '../../uicomponents/useSmallScreenDetection'
import './../../../css/layout.css'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  dialogInformationRoot: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },

  body: {
    fontSize: 16
  },

  dialogMedia: {
    display: 'block',
    backgroundColor: theme.palette.primary.dark,
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },

  typography: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto'
    // fontWeight: 100,
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    width: '100%',
    justifyContent: 'space-between'
  },
  infoRowWrapper: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  warningRed: {
    color: red[700]
  }
}))

const createTextRow = (classes, key, value, warning = false) => (
  <div className={clsx(classes.textRow)}>
    <Typography
      className={clsx(classes.typography, classes.body)}
      variant='body2'
      component='p'
    >
      {key}:
    </Typography>
    <Typography
      className={clsx(classes.typography, classes.body, {
        [classes.warningRed]: warning
      })}
      variant='body2'
      component='p'
    >
      {value}
    </Typography>
  </div>
)

const DATA_SIZE_WARNING_THRESHOLD = 4075

export default function DrawingTransactionDialog (props) {
  const classes = useStyles()

  const smallScreen = useSmallScreenDetection()

  const {
    open,
    croppedBase64PngString,
    numberOfPixels,
    blockchainPixelGroupDataLength,
    blockchainColourIndexDataLength,
    costInPaint,
    numberOfColors,
    tokenId,
    onCancelClicked,
    onDrawClicked
  } = props

  const shouldShowWarning =
    blockchainPixelGroupDataLength + blockchainColourIndexDataLength + 2 >=
    DATA_SIZE_WARNING_THRESHOLD

  return (
    <StyledImageViewerDialog
      open={open}
      withCloseButton
      onClose={onCancelClicked}
      dialogTitle={`Draw to #${tokenId} on The Grid`}
      dialogImageContent={() => (
        <img
          className={clsx(classes.dialogMedia, 'pixelated-image')}
          src={croppedBase64PngString}
          alt={'image to draw'}
        />
      )}
      dialogInformation={() => (
        <div className={clsx(classes.dialogInformationRoot)}>
          <div className={clsx(classes.infoRowWrapper)}>
            <div
              style={{
                display: 'inline-block',
                width: '50%',
                paddingTop: '16px'
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'stretch',
                  justifyContent: 'space-between',
                  height: '100%',
                  paddingRight: '16px'
                }}
              >
                {createTextRow(
                  classes,
                  'Total pixels',
                  numberOfPixels,
                  shouldShowWarning
                )}

                {createTextRow(classes, 'Number of colors', numberOfColors)}

                {createTextRow(
                  classes,
                  'Cost in PAINT',
                  costInPaint + ' PAINT'
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      dialogActions={() => (
        <React.Fragment>
          <Typography
            className={classes.typography}
            variant='body1'
            component='p'
            align='left'
            style={{
              flex: 1,
              marginLeft: '16px'
            }}
          >
            By clicking 'DRAW' you agree to the{' '}
            <Link
              component={RouterLink}
              to='/terms'
              color='secondary'
              underline='hover'
            >
              Terms and Conditions
            </Link>
            .
          </Typography>
          <Button
            size='medium'
            color='primary'
            onClick={() => {
              onDrawClicked()
            }}
            variant='contained'
            style={{ marginRight: '16px' }}
          >
            Draw
          </Button>
          <Button
            size='medium'
            color='primary'
            variant='outlined'
            onClick={onCancelClicked}
          >
            Cancel
          </Button>
        </React.Fragment>
      )}
    />
  )
}
