import React, { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import CardHeader from '@mui/material/CardHeader'
import Card from '@mui/material/Card'
import Skeleton from '@mui/material/Skeleton'
import _ from 'underscore'
import { CardActionArea, Avatar, CardMedia, Typography } from '@mui/material'
import clsx from 'clsx'
import { replaceIpfsIfNeeded } from '../../../js/libs/appUtils'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useGridNFTDataSource } from '../../../hooks/use-grid-nft-datasource'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    border: 'solid',
    borderColor: theme.palette.secondary.main,
    borderWidth: '1px'
  },

  media: {
    backgroundColor: theme.palette.primary.dark,
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '100%' // square
  }
}))

const PLACEHOLDER_IMAGE = 'images/logos/bs_x_murall_160.jpg'

export default function GridCardItem ({ title, tokenId, onClick }) {
  const classes = useStyles()

  const { getCurrentGridContents } = useGridNFTDataSource()

  const [isFetching, setIsFetching] = useState(false)
  const [shouldShowNumbers, setShouldShowNumbers] = useState(false)
  const [image, setImage] = useState(null)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    setIsFetching(true)
    setImage(PLACEHOLDER_IMAGE)
    setShouldShowNumbers(false)
    getCurrentGridContents(tokenId)
      .then(tokenInfo => {
        setShouldShowNumbers(false)
        setImage(tokenInfo.image)
      })
      .catch(err => {
        console.error(err)

        setImage(PLACEHOLDER_IMAGE)
        setShouldShowNumbers(true)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }, [tokenId])

  return (
    <Card className={clsx(classes.root)} elevation={0}>
      <CardActionArea
        onClick={() => {
          onClick && onClick(tokenId, image)
        }}
      >
        <CardHeader
          avatar={
            <Avatar
              src={'images/logos/bs_x_murall_160.jpg'}
              variant='square'
              {...(isMobile && { sx: { width: 16, height: 16 } })}
              {...(!isMobile && { sx: { width: 32, height: 32 } })}
            />
          }
          title={title ? title : <Skeleton variant='text' />}
          titleTypographyProps={{
            align: 'left',
            variant: isMobile ? 'body1' : 'h5',
            component: isMobile ? 'body1' : 'h5',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 300
          }}
          sx={{ padding: isMobile ? '0.5em' : '1em' }}
        />
        {isFetching || !tokenId || !image ? (
          <Skeleton className={clsx(classes.media)} variant='rectangular' />
        ) : (
          <CardMedia
            className={clsx('pixelated-image', classes.media)}
            image={image}
            title={title}
            alt={title}
          >
            {shouldShowNumbers && (
              <Typography
                variant='h6'
                align='justify'
                component='p'
                mr={0.5}
                color='black'
                sx={{
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: 300,
                  textShadow: '2px 2px 5px black'
                }}
              >
                #{tokenId}
              </Typography>
            )}
          </CardMedia>
        )}
      </CardActionArea>
    </Card>
  )
}
