import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#7E0AE5',
      dark: '#000000',
      gray: '#CCC'
    },
    secondary: {
      main: '#E91BFA',
      dark: '#000000'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1324,
      xl: 2024
    }
  },
  colors: {
    bg: {
      lightest: '#33333a',
      lighter: '#27272d',
      main: '#000000',
      darker: '#0f0f11',
      darkest: '#030304'
    },
    named: {
      red: '#ff1919',
      green: '#27AE60'
    }
  }
})

export default theme
