import { processGridNftData } from '../../js/modules/blockchain/GridtNftDataMapper'

export const processPaintedEvent = async (rawEvent) => {
  const event = rawEvent.args
  if (!event) return {}

  const nftInfo = await processGridNftData(
    rawEvent.address,
    event.tokenId,
    event.iteration,
    event.colorIndex,
    event.pixelGroups
  )

  return nftInfo
}

export default processPaintedEvent
