import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Divider from '@mui/material/Divider'
import MenuToggle from './menu-toggle'
import FramePickerDialog from '../../common/frames/frame-picker-dialog'
import { Icon } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import PulseBorder from '../../common/pulse-border'
import MurAllSocials from '../../common/socials/MurAllSocials'
import BoringSecuritySocials from '../../common/socials/BoringSecuritySocials'

const NavLinks = () => {
  const { account } = useWeb3React()
  const [isOpen, setOpen] = useState(false)
  const isLandscape = useMediaQuery('(orientation: landscape)')
  const [framePickerOpen, setFramePickerOpen] = useState(false)
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Container>
        <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
        {isOpen && (
          <LinksWrapper onClick={() => setOpen(false)}>
            <NavLink to="/state">
              <LinkItem>The Grid</LinkItem>
            </NavLink>
            <NavLink to="/mint">
              <LinkItem>Mint</LinkItem>
            </NavLink>
            <NavLink to="/faq">
              <LinkItem>FAQ</LinkItem>
            </NavLink>
            <Divider sx={{ margin: 1 }} />
            {account && (
              <>
                <NavLink to="/nfts">
                  <ExtraLinkItem>My NFTs</ExtraLinkItem>
                </NavLink>
              </>
            )}

            {account && (
              <NavLink
                onClick={() => {
                  setFramePickerOpen(true)
                }}
              >
                <ExtraLinkItem>Use Frames with The Grid</ExtraLinkItem>
                <img
                  src={'images/logos/frames.png'}
                  alt={'use frames with the grid'}
                  style={{
                    height: 25,
                    width: 25,
                    objectPosition: 'center',
                    objectFit: 'contain',
                    verticalAlign: 'text-top',
                  }}
                />
              </NavLink>
            )}

            <NavLink
              to={{
                pathname:
                  'https://opensea.io/collection/boring-security-supporter-grid',
              }}
              target="_blank"
            >
              <ExtraLinkItem>View on Opensea</ExtraLinkItem>
              <Icon
                style={{ fontSize: 'inherit', textAlign: 'center' }}
                sx={{ marginLeft: 'auto' }}
              >
                <img src={'images/logos/opensea.svg'} alt={'opensea'} />
              </Icon>
            </NavLink>
            <Border />
            <MurAllSocials sx={{ mt: 2 }} />
            <Divider variant="middle" sx={{ mt: 0.75 }} />
            <BoringSecuritySocials sx={{ mt: 1.5 }} />
          </LinksWrapper>
        )}
        {account && (
          <FramePickerDialog
            open={framePickerOpen}
            onClose={() => {
              setFramePickerOpen(false)
            }}
          />
        )}
      </Container>
    </ClickAwayListener>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`

const LinksWrapper = styled.ul`
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 5px;
  margin: 0;
  padding: ${({ theme }) => theme.spacing(1)} 0;
  display: flex;
  list-style: none;
  background: ${({ theme }) => theme.colors.bg.main};
  width: 100%;
  flex-direction: column;
  position: absolute;
  top: 65px;
  left: 0;
`

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
`

const LinkItem = styled.li`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  display: flex;
`

const ExtraLinkItem = styled(LinkItem)`
  display: inline-block;
  font-weight: 300;
  width: auto;
`

const Border = styled(PulseBorder)`
  position: relative;
  height: 1px;
  bottom: auto;
`

export default NavLinks
