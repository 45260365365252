import { createSlice } from '@reduxjs/toolkit'

const framesSlice = createSlice({
  name: 'frames',
  initialState: { assignedFrameId: null },
  reducers: {
    setSelectedFrameId: (state, action) => {
      state.assignedFrameId = action.payload
      return state
    }
  }
})

export const { setSelectedFrameId } = framesSlice.actions

export default framesSlice.reducer
