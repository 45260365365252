import config from '../config.js'
import Base_tools_class from '../core/base-tools.js'
import Layer_raster_class from '../modules/layer/raster.js'

class Rasterize_class extends Base_tools_class {
  constructor (ctx) {
    super()
    this.raster = new Layer_raster_class()
    this.name = 'rasterize'
  }

  load () {
    //nothing
  }

  render (ctx, layer) {
    //nothing
  }

  on_activate () {
    this.raster.raster()
  }
}
export default Rasterize_class
