import config from '../config.js'
import Base_tools_class from '../core/base-tools.js'
import Image_reduce_to_256_colors_class from '../modules/image/convert_to_256_colors.js'

class Palette256_class extends Base_tools_class {
  constructor (ctx) {
    super()
    this.reduceColors = new Image_reduce_to_256_colors_class()
    this.name = 'palette256'
  }

  load () {
    //nothing
  }

  render (ctx, layer) {
    //nothing
  }

  on_activate () {
    this.reduceColors.convert_to_256_color()
  }
}
export default Palette256_class
