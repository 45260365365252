import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Layout from './components/common/layout'

import LandingPage from './pages/landing'
import DrawPage from './pages/draw'
import LivePage from './pages/live'
import MintPage from './pages/mint'
import MyNftsPage from './pages/myNfts'
import FaqPage from './pages/faq'

const Routes = () => {
  return (
    <Layout>
      <Switch>
        <Route path='/draw/:id'>
          <DrawPage />
        </Route>
        <Route path='/state' component={LivePage} exact />
        <Route path='/mint' component={MintPage} exact />
        <Route path='/nfts' component={MyNftsPage} exact />
        <Route path='/faq' component={FaqPage} exact />
        <Route path='/' component={LandingPage} exact />
        <Redirect to='/' />
      </Switch>
    </Layout>
  )
}

export default Routes
