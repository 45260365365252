import { SupportedChain } from '../constants'

export const addToken = async ({
  library,
  tokenAddress,
  tokenSymbol,
  tokenDecimals,
  tokenImage
}) => {
  if (!library?.currentProvider?.request) return

  try {
    await library?.currentProvider.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage // A string url of the token logo
        }
      }
    })
  } catch (error) {
    console.error(
      `error adding token: `,
      tokenAddress,
      tokenSymbol,
      tokenDecimals,
      tokenImage,
      error
    )
  }
}

export const addPaintToken = async (library, chainId) => {
  return addToken({
    library,
    tokenAddress:
      chainId === SupportedChain.Ethereum
        ? '0x4C6eC08CF3fc987c6C4BEB03184D335A2dFc4042'
        : '0x7c28F627eA3aEc8B882b51eb1935f66e5b875714',
    tokenSymbol: 'PAINT',
    tokenDecimals: 18,
    tokenImage:
      'https://ipfs.io/ipfs/QmSqt6RAgNYWq3kJRhEoLquY2xcZ7QXAJGovaeaxmZYCVJ'
  })
}

export const launchUniswapForPaintToken = () => {
  window.open(
    'https://app.uniswap.org/#/swap?inputCurrency=0x4c6ec08cf3fc987c6c4beb03184d335a2dfc4042',
    '_blank'
  )
}
