import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

import { MAINNET_CHAINS } from './chains'

const [mainnet, ...optionalChains] = Object.keys(MAINNET_CHAINS).map(Number)

export const [walletConnectV2, hooks] = initializeConnector(
  actions =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: '93e1ed48e6a00b1f6d2e73d40164cf25',
        chains: [mainnet],
        optionalChains,
        metadata: {
          name: 'Boring Security Supporter Grid',
          description:
            'Join the Supporter Cult, and show your support for getting Boring Security out of the club basement and into an office with a working toilet!',
          url: 'https://boringsecurity.murall.art', // origin must match your domain & subdomain
          icons: [
            'https://boringsecurity.murall.art/images/favicon/android-chrome-512x512.png'
          ]
        },
        showQrModal: true
      }
    })
)
