import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useActiveWeb3React } from '../hooks/web3'
import { setNetwork } from './slices/network'
import { addPaintedEvents } from './slices/events'
import processPaintedEvent from '../lib/actions/process-painted-event'
import { paintedEvent } from '../lib/notification'
import useListenForPaintedEvents from '../hooks/grid/use-listen-for-painted-events'

export default () => {
  const dispatch = useDispatch()
  const { chainId } = useActiveWeb3React()
  const paintedEvents = useListenForPaintedEvents({ active: true })

  // change network in state
  useEffect(() => {
    if (!chainId) return
    dispatch(setNetwork({ id: parseInt(chainId) }))
  }, [chainId])

  // listen to Painted events
  useEffect(() => {
    if (paintedEvents?.length) {
      processPaintedEvent(paintedEvents[0]).then(event => {
        if (!event) return
        paintedEvent(event)
        dispatch(addPaintedEvents([event]))
      })
    }
  }, [paintedEvents, dispatch])

  return null
}
