import React from 'react'
import styled from 'styled-components'
import Accordion from '../components/common/Accordion'
import LazyImage from '../components/common/images/LazyImage'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Typography } from '@mui/material'
import DefaultContainer from '../components/common/DefaultContainer'
import Footer from '../components/common/footer/index'
import MemberBenefits from '../components/common/MemberBenefits'

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeblur': {
    '0%': {
      filter: 'blur(0px) brightness(100%)',
      webkitFilter: 'blur(0px) brightness(100%)',
    },
    '100%': {
      filter: 'blur(8px) brightness(50%)',
      webkitFilter: 'blur(8px) brightness(50%)',
    },
  },
  '@keyframes fade': {
    '0%': {
      filter: 'brightness(100%)',
      webkitFilter: 'brightness(100%)',
    },
    '100%': {
      filter: 'brightness(50%)',
      webkitFilter: 'brightness(50%)',
    },
  },

  fadeblur: {
    animationName: '$fade',
    animationDuration: '500ms',
    animationFillMode: 'forwards',
    webkitAnimationFillMode: 'forwards',
  },
}))

const FormLink = ({ text }) => {
  return (
    <Link
      href="https://forms.gle/taVJCS9Qr54jzPDm6"
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </Link>
  )
}

const Link = styled.a`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

const FAQ_ITEMS = [
  {
    title: 'What is the Boring Security Supporter Grid?',
    content:
      '10 x 7 grid of NFTs, with each NFT allowing access to draw inside ONE square on the supporter grid (as well as a whole other host of benefits!).',
  },
  {
    title: 'What do I need to draw on my piece on The Grid?',
    content:
      'In order to cover your 160 x 160 pixel grid portion it costs 1 PAINT per pixel totalling 25600 PAINT per coverage, plus any network fees. The used PAINT is burned as part of the transaction, and your artwork is stored on the blockchain itself.',
  },
  {
    title: 'How do I draw on my piece on The Grid?',
    content: (
      <Typography
        mb={1}
        sx={{
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: 300,
        }}
      >
        Go to The Grid and click on your square, then select EDIT to open the
        drawing tool. You can use the drawing tools to draw on your square, or
        you can upload an image to your square.
        <br />
        <br />
        Once you're happy with your artwork, click the big DRAW button in the
        top right. A prompt with a 160 x 160 px resolution preview of your piece
        is then shown.
        <br />
        (NOTE: each image must be a maximum of 256 colours thus you may be
        prompted to first reduce the colours, which happens automatically when
        you select "REDUCE AND CONTINUE" in the prompt).
        <br />
        <br />
        If you're happy with the piece hit the "DRAW" button in the prompt to
        commit your drawing to the blockchain, and you should see your wallet
        pop up to confirm the transaction. Your piece will be updated and you
        can view your new artwork on The Grid.
      </Typography>
    ),
  },
  {
    title: 'Can I import external images to draw on my Grid piece?',
    content:
      'Yes! In the drawing editor just go to File > Open > Open file and select your image. You can then draw on top of it in the editor.',
  },
  {
    title: 'What other benefits does owning a Supporter Grid NFT give me?',
    content: (
      <>
        <Typography
          mb={1}
          sx={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 300,
          }}
        >
          By holding the Supporter NFT you also have the following benefits:
        </Typography>
        <MemberBenefits />
      </>
    ),
  },
  {
    title: 'The DAO?',
    content:
      'With the passing of AIP-133, Boring Security will have a treasury that will be used to pay ongoing contributors, projects, and other DAO initiatives. Grid holders will be able to present proposals with our DAO tools (upcoming), access the #partners channel in Boring Security Discord, and will have a special place in the Boring Security community to help shape its future.',
  },
]

const FaqSection = () => {
  const classes = useStyles()
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <LazyImage
        className={classes.fadeblur}
        src={'images/BoringSecurityMurAll.png'}
        lqip={'images/BoringSecurityMurAll_small.png'}
        alt="Boring Security MurAll"
        containerStyle={{
          position: 'fixed',
          zIndex: -1,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
        }}
        style={{
          width: '100vw',
          height: '100vh',
          objectFit: 'cover',
        }}
      />

      <DefaultContainer>
        <Accordion items={FAQ_ITEMS} />
      </DefaultContainer>

      <Box
        sx={{
          width: '100%',
        }}
      >
        <Footer />
      </Box>
    </Box>
  )
}

export default FaqSection
