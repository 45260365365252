import { createSlice } from '@reduxjs/toolkit'

const eventsSlice = createSlice({
  name: 'events',
  initialState: { paintedEvents: [] },
  reducers: {
    addPaintedEvents: (state, action) => {
      state.paintedEvents = [...state.paintedEvents, ...action.payload]
      return state
    }
  }
})

export const { addPaintedEvents } = eventsSlice.actions

export default eventsSlice.reducer
