import React, { useState } from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'

const CustomAccordion = ({ items = [] }) => {
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false)

  const createAccordionSection = ({ id, title, content }) => {
    return (
      <Accordion
        disableGutters
        elevation={0}
        key={id}
        expanded={expanded === id}
        onChange={handleChange(id)}
      >
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
        >
          <Title variant="h6" component="h6">
            {title}
          </Title>
        </AccordionSummary>
        <MuiAccordionDetails>
          <Content>{content}</Content>
        </MuiAccordionDetails>
      </Accordion>
    )
  }

  return items.map((item, idx) =>
    createAccordionSection({
      title: item.title,
      content: item.content,
      id: `item-${idx}`,
    })
  )
}

const Accordion = styled(MuiAccordion)`
  flex: 1;
  width: 100%;
  height: 100%;
  transition: all 300ms ease-in-out;
  background-color: ${({ theme, expanded }) =>
    expanded ? '#00000099' : 'transparent'};
  box-shadow: none;
  backdrop-filter: ${({ theme, expanded }) =>
    expanded ? 'blur(10px)' : 'none'};
  border: 1px solid
    ${({ theme, expanded }) =>
      expanded ? theme.palette.primary.main : 'transparent'};
  &:before {
    display: none;
  }
`

const AccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: row-reverse;
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
`

const Title = styled(Typography)`
  font-family: Roboto;
  width: 100%;
  font-weight: 300;
  padding-left: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

const Content = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-family: Roboto;
  font-weight: 300;
  align: justify;
  padding: 0 ${({ theme }) => theme.spacing(4)};
`

export default CustomAccordion
