import { metaMask } from './metamask'
import { walletConnectV2 } from './walletconnectv2'
import injectedIcon from '../../../images/logos/injected.svg'
import metamaskIcon from '../../../images/logos/metamask.png'
import walletConnectIcon from '../../../images/logos/wallet-connect.svg'

export default {
  INJECTED: {
    connector: metaMask,
    name: 'Injected',
    icon: injectedIcon,
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: metaMask,
    name: 'MetaMask',
    icon: metamaskIcon,
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletConnectV2,
    name: 'WalletConnect',
    icon: walletConnectIcon,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  }
}
