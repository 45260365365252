import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import Base_layers_class from '../../js/core/base-layers.js'
import config from '../../js/config.js'
import notification from '../../lib/notification.js'
import { useGridNFTDataSource } from '../use-grid-nft-datasource.js'

const WHITE_IMAGE_DATA_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAAAXNSR0IArs4c6QAAAetJREFUeF7t2MENACAMw8Cy/7rwBok1fGxQ7EeSdfa542V/YBEgy/4fToA2fwLE+ROAAEJg2gEZII1fCIzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOH4CEMAO0HZABmjzVwPj/AlAADtA2gEZII1fDYzjJwAB7ABtB2SANn81MM6fAASwA6QdkAHS+NXAOP6ZB4Fq7xB1dURmAAAAAElFTkSuQmCC'

export const useUpdateTokenStateOnDrawingClient = tokenId => {
  const { getCurrentGridContents } = useGridNFTDataSource()
  const [loading, setLoading] = useState(false)

  const network = useSelector(state => state.network)
  const terms = useSelector(state => state.terms)
  const [cookies] = useCookies(['agreedToTerms'])

  // TODO uncomment when terms are ready
  // const agreedToTerms = !!cookies.agreedToTerms || terms.agreed
  const agreedToTerms = true

  const apply = useCallback(async () => {
    const Base_layers = new Base_layers_class()
    try {
      setLoading(true)
      const stateUpdatePromise = getCurrentGridContents(tokenId)
        .then(async function (tokenData) {
          if (
            config.blockchainDataLayer != null &&
            Base_layers.get_layer(config.blockchainDataLayer.id) != null
          ) {
            Base_layers.delete(config.blockchainDataLayer.id)
          }
          // create params for the new layer with transparent
          var params = {
            type: 'image',
            name: `Grid #${tokenId} blockchain data'`,
            data: tokenData ? tokenData.image : WHITE_IMAGE_DATA_URL,
            x: 0,
            y: 0,
            width: config.WIDTH,
            height: config.HEIGHT,
            opacity: 100
          }

          //create the new layer from these params
          await Base_layers.insert(params, false, true)
        })
        .catch(async function (error) {
          console.error(error)
          var params = {
            type: 'image',
            name: `Grid #${tokenId} blockchain data'`,
            data: tokenData ? tokenData.image : WHITE_IMAGE_DATA_URL,
            x: 0,
            y: 0,
            width: config.WIDTH,
            height: config.HEIGHT,
            opacity: 100
          }

          //create the new layer from these params
          await Base_layers.insert(params, false, true)
        })
        .finally(() => {
          setLoading(false)
        })

      notification.promise(stateUpdatePromise, {
        loading: `Loading state for Grid #${tokenId} to layer`,
        success: `Loaded state for Grid #${tokenId} to layer`,
        error: error => {
          return `Failed to load state for Grid #${tokenId}: ${error}`
        }
      })

      return
    } catch (error) {
      console.error(error)
      setLoading(false)
      return
    }
  }, [getCurrentGridContents, network, agreedToTerms])

  useEffect(() => {
    if (agreedToTerms && network.id && network.supported) {
      console.log('apply', network.id)
      apply()
    }
  }, [network, agreedToTerms])
  return { loading }
}

export default useUpdateTokenStateOnDrawingClient
