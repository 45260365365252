import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const BASE_URL = 'https://api.opensea.io/api'

export const OpenseaApi = createApi({
  reducerPath: 'openseaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/v1`
  }),
  endpoints: builder => ({
    getNfts: builder.query({
      query: ({ address, from = 0, limit = 10, contractAddresses = [] }) => {
        let url = `assets?owner=${address}&order_direction=desc&offset=${from}&limit=${limit}`

        if (contractAddresses.length) {
          for (let i = 0; i < contractAddresses.length; i++) {
            url = url.concat(
              `&asset_contract_addresses=${contractAddresses[i]}`
            )
          }
        }

        return {
          url,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'X-API-KEY': '2522df2fe8804723a43ee4e63410f374'
          }
        }
      },
      transformResponse: response => {
        const newData = response.assets.map(nft => {
          nft.tokenID = nft.token_id
          nft.contract = {
            id: nft.asset_contract.address,
            name: nft.asset_contract.name,
            symbol: nft.asset_contract.symbol,
            type: ''
          }
          return nft
        })

        return newData
      }
    })
  })
})

export const { useGetNftsQuery } = OpenseaApi
