import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import StyledDialog from '../../uicomponents/styled_dialog'
import { FormControlLabel, Checkbox, Typography, Link } from '@mui/material'
import { getDateInFuture } from '../../../lib/datetime'
import { setHideDrawWelcome } from '../../../state/slices/drawWelcome'
import { useDispatch, useSelector } from 'react-redux'

export default function WelcomeDrawDialog(props) {
  const [cookies, setCookie] = useCookies(['hideDrawWelcome'])
  const [remember, setRemember] = useState(false)
  const toggleRemember = (event) => setRemember(event.target.checked)
  const dispatch = useDispatch()

  const { onClose, open, onPositiveButtonClick } = props

  const onAgree = () => {
    if (remember) {
      setCookie('hideDrawWelcome', true, {
        path: '/',
        expires: getDateInFuture(180),
      })
    }
    dispatch(setHideDrawWelcome(true))

    onPositiveButtonClick && onPositiveButtonClick()
  }

  return (
    <StyledDialog
      open={open}
      withCloseButton
      onClose={onClose}
      maxWidth={'sm'}
      dialogTitle={'Make your artwork history on The Grid!'}
      dialogContent={() => (
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'auto',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            style={{ fontFamily: 'Roboto', fontWeight: 300 }}
            variant="h6"
            component="h6"
            color="textSecondary"
            align="justify"
          >
            Use our editor to create your image from scratch, or import one you
            have already made!
            <br />
            <br />
            Once you are happy with where it sits on the wall, mint your
            creation to solidify it as part of The Grid, with all artwork
            onchain.
            <br />
            <br />
            Remember drawing costs 1 PAINT per pixel + network transaction fees!
          </Typography>
        </div>
      )}
      dialogFooter={() => (
        <FormControlLabel
          style={{ flex: 1, paddingLeft: '1em' }}
          control={
            <Checkbox
              checked={remember}
              onChange={toggleRemember}
              name="rememberChoice"
            />
          }
          label={`Don't show next time`}
        />
      )}
      positiveButtonTitle={'Ok'}
      onPositiveButtonClick={onAgree}
    />
  )
}
