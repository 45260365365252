import React from 'react'
import { toast, Toaster, ToastBar } from 'react-hot-toast'
import styled from 'styled-components'
import ClearIcon from '@mui/icons-material/Clear'
import { Button } from '@mui/material'
import LayerRaster from '../../js/modules/layer/raster'

const CloseButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0.5;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`

const RasterizeButton = ({ show, onClick } = {}) => {
  if (!show) return null
  return <Button onClick={onClick} style={{ width: '25%' }}>Rasterize</Button>
}

const CloseButton = ({ show, onClick } = {}) => {
  if (!show) return null
  return <CloseButtonWrapper onClick={onClick}><ClearIcon /></CloseButtonWrapper>
}

const Notifications = () => {
  const layerRaster = new LayerRaster()
  return (
    <Toaster position='bottom-center' reverseOrder={false}>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              <RasterizeButton show={t.showRasterize} onClick={() => {
                layerRaster.raster()
                toast.dismiss(t.id)
              }} />
              <CloseButton
                show={t.type !== 'loading' && !t.showRasterize}
                onClick={() => toast.dismiss(t.id)}
              />
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default Notifications
