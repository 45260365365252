//css
import './../../../css/reset.css'
import './../../../css/layout.css'
import './../../../css/menu.css'
import './../../../css/print.css'
import './../../../css/slider.css'

//js
import React from 'react'
import config from '../../config'
import Base_gui_class from '../../core/base-gui'
import Base_layers_class from '../../core/base-layers'
import Base_tools_class from '../../core/base-tools'
import withStyles from '@mui/styles/withStyles'
import Base_state_class from '../../core/base-state'

import BraveBugWarningDialog from './braveBugWarningDialog'
import StyledDialog from '../../uicomponents/styled_dialog'
import LoadingDialog from '../../uicomponents/loading_dialog'
import Typography from '@mui/material/Typography'
import _ from 'underscore'
import notification from '../../../lib/notification'

const useStyles = theme => ({
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  }
})

class DrawingClient extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      showLoading: false,
      drawLayersRequested: false,
      promptFlattenLayers: false,
      isBraveBrowser: false
    }
  }

  componentDidMount () {
    const drawingContainer = document.querySelector('#drawingContainer')
    this.setVisibility(drawingContainer, true)
    drawingContainer.addEventListener(
      'drawClicked',
      this.onDrawEventReceived.bind(this),
      false
    )
    drawingContainer.addEventListener(
      'changeLoadingVisibility',
      this.onChangeLoadingVisibilityEventReceived.bind(this),
      false
    )

    // forcing state update to ensure componentDidUpdate fires
    this.setState({ loaded: true })
    this.warnBraveBrowserUserIfNecessary()
  }

  componentWillUnmount () {
    this.setState({ loaded: false })
    const drawingContainer = document.querySelector('#drawingContainer')
    this.setVisibility(drawingContainer, false)
    drawingContainer.removeEventListener(
      'drawClicked',
      this.onDrawEventReceived,
      false
    )
    drawingContainer.removeEventListener(
      'changeLoadingVisibility',
      this.onChangeLoadingVisibilityEventReceived,
      false
    )
  }

  async updateTheme () {
    const l2 = this.props.isL2

    for (var i in config.themes) {
      document
        .querySelector('body')
        .classList.remove('theme-' + config.themes[i])
    }
    if (l2) {
      console.log('changing to l2 theme')
      document.querySelector('body').classList.add('theme-' + config.themes[1])
    } else {
      console.log('changing to dark theme')
      document.querySelector('body').classList.add('theme-' + config.themes[0])
    }
  }

  componentDidUpdate () {
    // dom is loaded and ready at this time so safe to initiate as sizes will be correct
    if (_.isNull(window.Layers) || _.isUndefined(window.Layers)) {
      //initiate app
      var Layers = new Base_layers_class()
      var Base_tools = new Base_tools_class(true)
      var GUI = new Base_gui_class()
      var Base_state = new Base_state_class()

      //register as global for quick or external access
      window.Layers = Layers
      window.State = Base_state // window.State.save();

      //render all
      GUI.load_modules()
      GUI.load_default_values()
      GUI.render_main_gui()

      Layers.init(this.props.tokenId)
    }
    this.updateTheme()
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        {this.state.promptFlattenLayers && (
          <StyledDialog
            open={this.state.promptFlattenLayers}
            onClose={() => {
              this.setState({ promptFlattenLayers: false })
            }}
            dialogTitle={'Flatten layers?'}
            dialogContent={() => (
              <Typography
                className={classes.typography}
                variant='body2'
                component='p'
              >
                In order to mint your drawing needs to be flattened to 1 layer
                to mint as a single piece on The Grid; do you wish to continue
                to flatten the image?
              </Typography>
            )}
            positiveButtonTitle={'Flatten'}
            onPositiveButtonClick={() => {
              this.setState({
                promptFlattenLayers: false
              })
              this.props.constructDrawTransactionInfo(true)
            }}
            negativeButtonTitle={'Cancel'}
            onNegativeButtonClick={() => {
              this.setState({
                promptFlattenLayers: false
              })
            }}
          />
        )}

        {this.props.agreedToTerms && (
          <LoadingDialog open={this.state.showLoading} />
        )}

        {this.props.agreedToTerms && this.state.isBraveBrowser && (
          <BraveBugWarningDialog
            open={this.state.isBraveBrowser}
            onClose={() => {
              this.setState({ isBraveBrowser: false })
            }}
            onPositiveButtonClick={() => {
              this.setState({ isBraveBrowser: false })
            }}
          />
        )}
      </div>
    )
  }

  async warnBraveBrowserUserIfNecessary () {
    const isBrave =
      (navigator.brave && (await navigator.brave.isBrave())) || false
    this.setState({ isBraveBrowser: isBrave })
  }

  setVisibility (target, visible) {
    if (visible) {
      target.style.display = 'block'
    } else {
      target.style.display = 'none'
    }
  }

  isVisible (target) {
    return target.style.display != 'none'
  }

  async onDrawEventReceived (e) {
    if (!this.props.connected) {
      notification.error(
        'Wallet not connected - please connect wallet to paint!'
      )
      return
    }

    if (!this.props.supportedNetworkConnected) {
      notification.error(
        'Please switch to Ethereum mainnet to draw!'
      )

      return
    }
    if (config.layers.length == 1 && config.blockchainDataLayer != null) {
      notification.error(
        'There is nothing to mint!\nDraw something or import an image from the file menu!'
      )
    } else if (config.layers.length > 2) {
      this.setState({ promptFlattenLayers: true })
    } else {
      this.props.constructDrawTransactionInfo(true, false)
    }
  }

  onChangeLoadingVisibilityEventReceived (e) {
    this.setState({ showLoading: e.detail.showLoading })
  }
}

export default withStyles(useStyles, { withTheme: true })(DrawingClient)
