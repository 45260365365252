import React, { useRef, useCallback, useState, useEffect } from 'react'
import _ from 'underscore'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom'

import './../../css/layout.css'
import TransparentDialog from './transparent_dialog'
import useSmallScreenDetection from './useSmallScreenDetection'

const useStyles = makeStyles(theme => ({
  dialogMedia: {
    width: '100%' /* Full width */,
    objectFit: 'contain'
  }
}))

export default function ImagePreviewDialog (props) {
  const classes = useStyles()
  const smallScreen = useSmallScreenDetection()
  const dialogContentRef = useRef()
  const [imageHeight, setImageHeight] = useState(0)
  const imgCroppedRef = useRef()

  const onImageCroppedUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgCroppedRef

    if (img) {
      const value = make3dTransformValue({ x, y, scale })

      img.style.setProperty('transform', value)
    }
  }, [])

  useEffect(() => {
    function handleResize () {
      updateExpandedHeight()
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  const updateExpandedHeight = () => {
    if (dialogContentRef.current) {
      setImageHeight(dialogContentRef.current.getBoundingClientRect().height)
    }
  }

  const measuredRef = useCallback(node => {
    if (node !== null) {
      dialogContentRef.current = node
      setImageHeight(node.getBoundingClientRect().height)
    }
  }, [])

  const { image, alt } = props

  return (
    <TransparentDialog
      {...props}
      withCloseButton
      dialogContentRef={measuredRef}
      dialogContent={() => (
        <QuickPinchZoom
          onUpdate={onImageCroppedUpdate}
          shouldInterceptWheel={() => false}
        >
          <img
            ref={imgCroppedRef}
            className={clsx('pixelated-image', classes.dialogMedia)}
            style={{
              height: imageHeight != 0 ? imageHeight + 'px' : 100 + 'vh'
            }}
            src={image}
            alt={alt}
          />
        </QuickPinchZoom>
      )}
    />
  )
}
