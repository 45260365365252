import { useState, useEffect, useCallback } from 'react'
import { useMintManagerContract } from '../use-contract'
import { ethers } from 'ethers'

export const useGetMintCost = (amount, immediate = true) => {
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)

  const mintManagerContract = useMintManagerContract()

  const execute = useCallback(async () => {
    if (!mintManagerContract) return

    setIsLoading(true)
    setValue(null)
    setError(null)

    try {
      const publicSalePrice = await mintManagerContract?.mintPricePublic()

      const mintCost = ethers.utils.formatUnits(
        ethers.BigNumber.from(publicSalePrice).mul(amount).toString(),
        'ether'
      )

      setValue(mintCost)
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }, [mintManagerContract, amount])

  useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])
  return { execute, isLoading, value, error }
}

export default useGetMintCost
