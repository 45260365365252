import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { Typography, Link, Grid } from '@mui/material'
import CollabLogosView from '../collab-logos-view'
const Logo = () => {
  return (
    <Link component={RouterLink} underline='none' to='/'>
      <LogoWrapper>
        <LogoImg>
          <CollabLogosView style={{ height: '100%', padding: 0, margin: 0 }} />
        </LogoImg>
      </LogoWrapper>
    </Link>
  )
}

const LogoWrapper = styled.div`
  display: flex;
  align-items: stretch;
  align-content: center;
  justify-content: center;
`

const LogoImg = styled.div`
  height: 40px;
  width: 90px;
  img {
    width: 100%;
    height: 100%;
  }
`

export default Logo
