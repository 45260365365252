import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import ExternalLink from '../links/external'
import PulseBorder from '../pulse-border'

import TwitterIcon from '@mui/icons-material/Twitter'
import DiscordLogo from '../../../../images/logos/discord.svg'

const BoringSecuritySocials = ({ sx }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isLandscape = useMediaQuery('(orientation: landscape)')
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        sx={{
          ...sx,
          width: '100%',
          pl: 1.5,
          pr: 1.5,
        }}
      >
        <Grid item xs={4}>
          <img
            src={'images/logos/BoringSecurityWatermark_long.png'}
            // src={'images/logos/boring_security.png'}
            alt="MurAll logo"
            style={{ height: '20px' }}
          />
        </Grid>
        <Grid item xs={8}>
          <SocialsWrapper>
            <ExternalLink
              href="https://twitter.com/BoringSecDAO"
              icon={<TwitterIcon mx={0.5} fontSize="small" />}
            />
            <ExternalLink
              href="https://discord.gg/hAHv3y69gd"
              icon={<CustomSocialIcon src={DiscordLogo} alt="Discord" />}
            />
          </SocialsWrapper>
        </Grid>
      </Grid>
    </>
  )
}

const CustomSocialIcon = ({ src, alt }) => {
  return (
    <Icon mr={1} ml={0.5}>
      <img src={src} alt={alt} />
    </Icon>
  )
}

const SocialsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Border = styled(PulseBorder)`
  height: 1px;
  bottom: auto;
`

export default BoringSecuritySocials
