import React from 'react'
import { Grid } from '@mui/material'
import { useTheme } from 'styled-components'
import Fade from '../../reveal/in-and-out/Fade'
import Rotate from '../../reveal/in-and-out/Rotate'

export default function CollabLogosView ({ style, withGlow, glowColor }) {
  const theme = useTheme()

  return (
    <Grid
      container
      spacing={0}
      justifyContent='center'
      alignItems='center'
      style={{
        ...style
      }}
    >
      <Grid item xs={4}>
        <Fade left delay={500}>
          <img
            src={'images/logos/MurAllGRID.png'}
            alt='MurAll logo'
            style={{
              width: '100%',
              height: '100%',
              ...(withGlow && {
                filter: `drop-shadow(0 0 0.75rem ${glowColor || theme.palette.secondary.main}) brightness(100%)`
              })
            }}
          />
        </Fade>
      </Grid>
      <Grid item xs={4}>
        <Rotate delay={500}>
          <img
            src={'images/logos/xGRID.png'}
            alt='X logo'
            style={{
              width: '100%',
              height: '100%',
              ...(withGlow && {
                filter: `drop-shadow(0 0 0.95rem ${glowColor || theme.palette.secondary.main}) brightness(100%)`
              })
            }}
          />
        </Rotate>
      </Grid>
      <Grid item xs={4}>
        <Fade right delay={500}>
          <img
            src={'images/logos/BoringGRID.png'}
            alt='Boring Security'
            style={{
              width: '100%',
              height: '100%',
              ...(withGlow && {
                filter: `drop-shadow(0 0 0.75rem ${glowColor || theme.palette.secondary.main}) brightness(100%)`
              })
            }}
          />
        </Fade>
      </Grid>
    </Grid>
  )
}
