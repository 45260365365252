import React from 'react'
import styled from 'styled-components'
import SUPPORTED_WALLETS from '../../../../lib/connectors/supported-wallets'
import { useActiveWeb3React } from '../../../../hooks/web3'
import { metaMask as injected } from '../../../../lib/connectors/metamask'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import AccountAddress from '../address'
import AddressActions from './address-actions'
import AccountPaintBalance from '../paint-balance'
import { Divider } from '@mui/material/index'
import PaintActions from './paint-actions'
import useMediaQuery from '@mui/material/useMediaQuery'

const AccountView = ({ onChangeClick }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Container>
      <ConnectionInfo>
        <ConnectionName />
        <ConnectionButtons onChange={onChangeClick} />
      </ConnectionInfo>
      <AccountAddress />
      <AddressActions />
      <Divider sx={{ pt: '1em', mb: '1em' }} />
      <RoundedContainer>
        <AccountPaintBalance
          textSize={'h5'}
          showPaintText={!isMobile}
          style={{ alignSelf: 'center', marginBottom: '1em' }}
        />
        <PaintActions />
      </RoundedContainer>
    </Container>
  )
}

const ConnectionName = () => {
  const { connector } = useActiveWeb3React()
  const { ethereum } = window
  const isMetaMask = !!(ethereum && ethereum.isMetaMask)
  const name = Object.keys(SUPPORTED_WALLETS)
    .filter(
      k =>
        SUPPORTED_WALLETS[k].connector === connector &&
        (connector !== injected || isMetaMask === (k === 'METAMASK'))
    )
    .map(k => SUPPORTED_WALLETS[k].name)[0]

  return (
    <Typography color="text.secondary" variant="caption">
      Connected with {name}
    </Typography>
  )
}

const ConnectionButtons = ({ onChange }) => {
  const { connector } = useActiveWeb3React()
  return (
    <ConnectionButtonsContainer>
      <ConnectionButton
        onClick={onChange}
        variant="outlined"
        size="small"
        startIcon={<LoopOutlinedIcon />}
      >
        Change
      </ConnectionButton>
      {connector !== injected && (
        <ConnectionButton
          onClick={() => connector.deactivate()}
          variant="outlined"
          size="small"
          startIcon={<CancelOutlinedIcon />}
        >
          Disconnect
        </ConnectionButton>
      )}
    </ConnectionButtonsContainer>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
`

const RoundedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bg.lightest};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 15px;
  padding-top: ${({ theme }) => theme.spacing(1.5)};
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
`

const ConnectionInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`

const ConnectionButtonsContainer = styled.div`
  margin-left: auto;
  width: min-content;
`

const ConnectionButton = styled(Button)`
  color: ${({ theme }) => theme.palette.text.secondary};
  border-radius: 15px;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`

export default AccountView
