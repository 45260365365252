import React from 'react'
import styled from 'styled-components'

import NavBar from './navbar'
import LegalDialog from './dialogs/legal'

const Layout = ({ children }) => {
  return (
    <LayoutContainer>
      <NavBar />
      <Content>{children}</Content>
      <LegalDialog />
    </LayoutContainer>
  )
}

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Content = styled.section`
  position: fixed;
  top: 65px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
`

export default Layout
