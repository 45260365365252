import _ from 'underscore'
import config from '../config'
const BigNumber = require('bignumber.js')
import Worker from '../workers/blockchain/getImageCropData.worker'
import Worker256Color from '../workers/color/reduceCanvasTo256Color.worker'
import Web3 from 'web3'
import { ethers } from 'ethers'

export const convertCanvasTo256Color = (
  canvas,
  params = { dithKern: 'FloydSteinberg', dithSerp: true }
) => {
  return new Promise(function (resolve, reject) {
    var ctx = canvas.getContext('2d')
    // setup rgb quant
    const worker = new Worker256Color()
    worker.onmessage = async function (msg) {
      worker.terminate()
      var buf = msg.data.canvasBuffer
      var arr = new Uint8ClampedArray(buf)
      let processedImageData
      try {
        processedImageData = new ImageData(
          arr,
          imageData.width,
          imageData.height
        )
      } catch (e) {
        processedImageData = ctx.createImageData(
          imageData.width,
          imageData.height
        )
        processedImageData.data.set(arr)
      }

      ctx.putImageData(processedImageData, 0, 0)

      resolve()
    }

    // reduce images
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const buffers = [imageData.data.buffer]
    const message = {
      canvasBuffer: imageData.data.buffer,
      dithKern: params.dithKern,
      dithSerp: params.dithSerp,
      alphaEnabled: config.layer.alphaEnabled,
      width: canvas.width,
      height: canvas.height,
      buffers: buffers,
      isLittleEndian: isLittleEndian()
    }

    worker.postMessage(message, buffers)
  })
}

export const getUrlParameters = (url = location.search) => {
  const queryDict = {}
  url
    .substr(1)
    .split('&')
    .forEach(function (item) {
      queryDict[item.split('=')[0]] = item.split('=')[1]
    })

  return queryDict
}

export const encodeQueryData = data => {
  const ret = _.map(data, (value, idx) => {
    if (typeof value === 'null' || typeof value === 'undefined') {
      return encodeURIComponent(idx)
    } else if (typeof value === 'object' || typeof value === 'array') {
      return _.map(
        value,
        (subVal, subIdx) =>
          `${encodeURIComponent(subIdx)}[]=${encodeURIComponent(subVal)}`
      )
    } else {
      return `${encodeURIComponent(idx)}=${encodeURIComponent(value)}`
    }
  })

  return _.flatten(ret).join('&')
}

export const overrideUrlQueries = (
  { location: { pathname, search }, history },
  data
) => {
  let endIndex = search.indexOf('?')
  if (endIndex == -1) {
    endIndex = search.length
  }
  const currentPath = search.slice(0, endIndex)
  const queryParams = encodeQueryData(data)
  const searchParams = new URLSearchParams(currentPath + queryParams)

  history.push({
    pathname,
    search: searchParams.toString()
  })
}

export const addQueryToUrl = (
  { location: { pathname, search }, history },
  key,
  value
) => {
  let searchParams = new URLSearchParams(search)

  searchParams.append(key, value)
  history.push({
    pathname,
    search: searchParams.toString()
  })
}

export const removeQueryFromUrl = (
  { location: { pathname, search }, history },
  key
) => {
  let searchParams = new URLSearchParams(search)

  searchParams.delete(key)
  history.push({
    pathname,
    search: searchParams.toString()
  })
}

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export const getRandomArbitrary = (min, max) => {
  return Math.random() * (max - min) + min
}

export const rgbToHex = (r, g, b) => {
  if (r > 255 || g > 255 || b > 255) throw 'Invalid color component'
  let tmp = ((r << 16) | (g << 8) | b).toString(16)

  return '#' + ('000000' + tmp).slice(-6)
}

export const rgb2hex_all = rgb => {
  rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
  return '#' + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3])
}

export const hex = x => {
  x = parseInt(x)
  return ('0' + x.toString(16)).slice(-2)
}

export const hex2rgb = hex => {
  if (hex[0] == '#') hex = hex.substr(1)
  if (hex.length == 3) {
    let temp = hex
    hex = ''
    temp = /^([a-f0-9])([a-f0-9])([a-f0-9])$/i.exec(temp).slice(1)
    for (let i = 0; i < 3; i++) hex += temp[i] + temp[i]
  }
  let triplets = /^([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})$/i.exec(hex).slice(1)
  return {
    r: parseInt(triplets[0], 16),
    g: parseInt(triplets[1], 16),
    b: parseInt(triplets[2], 16),
    a: 255
  }
}

//credits: richard maloney 2006
export const darkenColor = (color, v) => {
  if (color.length > 6) {
    color = color.substring(1, color.length)
  }
  let rgb = parseInt(color, 16)
  let r = Math.abs(((rgb >> 16) & 0xff) + v)
  if (r > 255) r = r - (r - 255)
  let g = Math.abs(((rgb >> 8) & 0xff) + v)
  if (g > 255) g = g - (g - 255)
  let b = Math.abs((rgb & 0xff) + v)
  if (b > 255) b = b - (b - 255)
  r = Number(r < 0 || isNaN(r)) ? 0 : (r > 255 ? 255 : r).toString(16)
  if (r.length == 1) r = '0' + r
  g = Number(g < 0 || isNaN(g)) ? 0 : (g > 255 ? 255 : g).toString(16)
  if (g.length == 1) g = '0' + g
  b = Number(b < 0 || isNaN(b)) ? 0 : (b > 255 ? 255 : b).toString(16)
  if (b.length == 1) b = '0' + b
  return '#' + r + g + b
}

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  let byteCharacters = atob(b64Data)
  let byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)

    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    let byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  let blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const escapeHtml = text => {
  return text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

export const isNumeric = n => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

/**
 * Converts an HSL color value to RGB.
 * Assumes h, s, and l are contained in the set [0, 1]
 * Returns r, g, and b in the set [0, 255].
 *
 * @param {number} h The hue
 * @param {number} s The saturation
 * @param {number} l The lightness
 * @return {Array} The RGB representation
 */
export const hslToRgb = (h, s, l) => {
  let r, g, b

  if (s == 0) {
    r = g = b = l // achromatic
  } else {
    let q = l < 0.5 ? l * (1 + s) : l + s - l * s
    let p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
}

export const hue2rgb = (p, q, t) => {
  if (t < 0) t += 1
  if (t > 1) t -= 1
  if (t < 1 / 6) return p + (q - p) * 6 * t
  if (t < 1 / 2) return q
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
  return p
}

/**
 * Converts an RGB color value to HSL. Values are in range 0-1.
 * But real ranges are 0-360, 0-100%, 0-100%
 *
 * @param {number} r red color value
 * @param {number} g green color value
 * @param {number} b blue color value
 * @return {object} The HSL representation
 */
export const rgbToHsl = (r, g, b) => {
  r /= 255
  g /= 255
  b /= 255
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h,
    s,
    l = (max + min) / 2

  if (max == min) {
    h = s = 0 // achromatic
  } else {
    let d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return { h, s, l }
}

/**
 * Runs a test to check if the browser is little or big endian by altering a colour value and seeing if the values are reversed or not
 * @return True if little endian
 */
export const isLittleEndian = () => {
  let isLittleEndian = true
  let buf = new ArrayBuffer(4)
  let buf8 = new Uint8ClampedArray(buf)
  let data = new Uint32Array(buf)
  data[0] = 0x0f000000
  if (buf8[0] === 0x0f) {
    isLittleEndian = false
  }

  return isLittleEndian
}

/**
 * @param ctx The canvas context
 * @param preserveCanvas True if you only want to get information about the cropped area without altering the canvas
 * @returns The information on the cropped position/area and the original width/height */
export const cropImageFromCanvasContext = (ctx, preserveCanvas = false) => {
  let canvas = ctx.canvas,
    w = canvas.width,
    h = canvas.height,
    pix = { x: [], y: [] },
    imageData = ctx.getImageData(0, 0, canvas.width, canvas.height),
    x,
    y,
    index
  let originalWidth = canvas.width
  let originalHeight = canvas.height
  for (y = 0; y < h; y++) {
    for (x = 0; x < w; x++) {
      index = (y * w + x) * 4
      if (imageData.data[index + 3] > 0) {
        pix.x.push(x)
        pix.y.push(y)
      }
    }
  }
  pix.x.sort(function (a, b) {
    return a - b
  })
  pix.y.sort(function (a, b) {
    return a - b
  })
  let n = pix.x.length - 1
  let yStart = 0
  let yEnd = 0
  let xStart = 0
  let xEnd = 0
  if (n > 0) {
    w = 1 + pix.x[n] - pix.x[0]
    h = 1 + pix.y[n] - pix.y[0]

    yStart = pix.y[0]
    yEnd = yStart + h

    xStart = pix.x[0]
    xEnd = xStart + w
    if (!preserveCanvas) {
      let cut = ctx.getImageData(pix.x[0], pix.y[0], w, h)
      canvas.width = w
      canvas.height = h
      ctx.putImageData(cut, 0, 0)
    }
  } else {
    if (!preserveCanvas) {
      canvas.width = 0
      canvas.height = 0
    }
  }

  return {
    start: { x: xStart, y: yStart },
    end: { x: xEnd, y: yEnd },
    width: originalWidth,
    height: originalHeight,
    croppedWidth: w,
    croppedHeight: h
  }
}

export const cropImage = (ctx, x, y, w, h) => {
  let cut = ctx.getImageData(x, y, w, h)
  ctx.canvas.width = w
  ctx.canvas.height = h
  ctx.putImageData(cut, 0, 0)
}

export const getImageCropDataFromCanvasContext = ctx => {
  return new Promise(function (resolve, reject) {
    const worker = new Worker()
    const img = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height)
    worker.onmessage = function (msg) {
      worker.terminate()
      resolve(msg.data)
    }

    const buffers = [img.data.buffer]
    const message = {
      canvasBuffer: img.data.buffer,
      canvasWidth: ctx.canvas.width,
      canvasHeight: ctx.canvas.height,
      buffers
    }

    worker.postMessage(message, buffers)
  })
}

export const createOffscreenCanvas = (width, height) => {
  let offScreenCanvas = document.createElement('canvas')
  offScreenCanvas.width = width
  offScreenCanvas.height = height

  return offScreenCanvas //return canvas element
}

export const loadImage = url => {
  return new Promise((res, rej) => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = url
    img.onload = e => res(img)
    img.onerror = rej
  })
}

export const rgb565HexToRgba = rgb565Hex => {
  let rgb565Int = parseInt(rgb565Hex)
  let r = (((rgb565Int >> 11) & 0x1f) * 527 + 23) >> 6
  let g = (((rgb565Int >> 5) & 0x3f) * 259 + 33) >> 6
  let b = ((rgb565Int & 0x1f) * 527 + 23) >> 6

  let RGB888 = (r << 16) | (g << 8) | b
  let RGB = hexToBytes6(RGB888.toString(16))
  let RGBAHex = '0x'.concat(RGB).concat('FF')

  return parseInt(RGBAHex)
}

export const rgb565HexToRgbHex = rgb565Hex => {
  let rgb565Int = parseInt(rgb565Hex)
  let r = (((rgb565Int >> 11) & 0x1f) * 527 + 23) >> 6
  let g = (((rgb565Int >> 5) & 0x3f) * 259 + 33) >> 6
  let b = ((rgb565Int & 0x1f) * 527 + 23) >> 6

  let RGB888 = (r << 16) | (g << 8) | b
  return hexToBytes6(RGB888.toString(16))
}

export const int24colourToRgb565Hex = color_24_bit_int => {
  let r = (color_24_bit_int >> 16) & 0xff
  let g = (color_24_bit_int >> 8) & 0xff
  let b = color_24_bit_int & 0xff

  let r565 = r >> 3
  let g565 = g >> 2
  let b565 = b >> 3

  let rgb565 = (r565 << 11) ^ (g565 << 5) ^ b565

  let hexVal = Number(rgb565).toString(16)

  return hexToBytes4(hexVal)
}

export const overwriteStringAtIndex = (originalString, toInsert, index) => {
  return (
    originalString.slice(0, index) +
    toInsert +
    originalString.slice(index + toInsert.length)
  )
}

/**
 * @return The hex bytes padded with any missing zeroes
 */
export const hexToBytes6 = hexVal => {
  return '000000'.concat(hexVal).slice(-6)
}

/**
 * @return The hex bytes padded with any missing zeroes
 */
export const hexToBytes4 = hexVal => {
  return String('0000' + hexVal).slice(-4)
}

export const isTransactionSuccess = receipt => {
  return receipt.status == '0x1' || receipt.status == 1
}

export const dispatchCustomEvent = (targetId, eventName, detailsObject) => {
  const event = new CustomEvent(eventName, {
    detail: detailsObject
  })

  document.querySelector('#' + targetId).dispatchEvent(event)
}

export const truncate = (fullStr, startLen, endLen, separator = '...') => {
  if (fullStr.length <= startLen) return fullStr

  return fullStr
    .substr(0, Math.ceil(startLen))
    .concat(separator, fullStr.substr(fullStr.length - Math.floor(endLen)))
}

export const convertTokenToProperDp = (amount, decimals) => {
  return new BigNumber(amount.toString()).dividedBy(
    new BigNumber(10).exponentiatedBy(new BigNumber(decimals.toString()))
  )
}

const IPFS_GATEWAY_PINATA_MURALL = 'https://murall.mypinata.cloud/ipfs/'

export const replaceIpfsIfNeeded = url => {
  if (url.indexOf('ipfs://ipfs/') !== -1) {
    url = url.replace('ipfs://ipfs/', IPFS_GATEWAY_PINATA_MURALL)
  } else if (url.indexOf('ipfs://') !== -1) {
    url = url.replace('ipfs://', IPFS_GATEWAY_PINATA_MURALL)
  } else if (url.startsWith('/ipfs/')) {
    url = IPFS_GATEWAY_PINATA_MURALL + url.substring(6)
  } else if (url.startsWith('Qm')) {
    url = IPFS_GATEWAY_PINATA_MURALL + url
  } else if (url.startsWith('ar://')) {
    url = url.replace('ar://', 'https://arweave.net/')
  }
  return url
}

export const replaceTokenIdIfNeeded = (url, tokenId) => {
  if (url.indexOf('{id}') !== -1) {
    const hexNumber = Web3.utils.padLeft(Web3.utils.numberToHex(tokenId), 64)
    url = url.replace('{id}', hexNumber.slice(2))
  }
  return url
}

export const ensureNumber = potentialBigNumber => {
  if (ethers.BigNumber.isBigNumber(potentialBigNumber)) {
    return potentialBigNumber.toNumber()
  } else if (isNumeric(potentialBigNumber)) {
    return Number(potentialBigNumber)
  } else {
    return potentialBigNumber
  }
}

/**
 * removes smoothing from canvas
 *
 * @param {ctx} ctx
 */
export const disableCanvasSmooth = ctx => {
  ctx.webkitImageSmoothingEnabled = false
  ctx.oImageSmoothingEnabled = false
  ctx.msImageSmoothingEnabled = false
  ctx.imageSmoothingEnabled = false
}

export const findLinksInText = text => {
  var urlRegex = /(https?:\/\/[^\s]+)/g

  return text.match(urlRegex)
}

export const getSvgCodeFromFile = svgFilePath => {
  return new Promise((res, rej) => {
    fetch(svgFilePath)
      .then(result => res(result.text()))
      .catch(rej)
  })
}

export const openInNewTab = url => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}
