import { useContext, useMemo } from 'react'
import { useActiveWeb3React } from './web3'
import { SupportedChain } from '../lib/constants'
import { EthersL1Context, EthersL1WebsocketContext } from '../main'
import { ethers } from 'ethers'
import getEthersProvider from './getEthersProvider'

export const useEthersProviderForChainId = (
  chainId,
  forceArchiveNode = false,
  websocketInstance = false
) => {
  const { provider, chainId: connectedChainId } = useActiveWeb3React()

  const ethersProviderL1 = useContext(EthersL1Context)
  const ethersWebsocketProviderL1 = useContext(EthersL1WebsocketContext)

  return useMemo(() => {
    let providerToUse = null
    if (websocketInstance) {
      if (chainId === SupportedChain.Ethereum) {
        providerToUse = ethersWebsocketProviderL1
      } else {
        console.error('Unsupported chainId for websocket provider')
        return null
      }
    } else if (forceArchiveNode) {
      if (chainId === SupportedChain.Ethereum) {
        providerToUse = ethersProviderL1
      } else {
        providerToUse = getEthersProvider(chainId)
        if (!providerToUse) {
          console.error('Unsupported chainId for archive node provider')
          return null
        }
      }
    } else {
      let combinedFallbackProvider =
        chainId === connectedChainId && provider?.provider
          ? getEthersProvider(chainId, provider)
          : getEthersProvider(chainId)

      providerToUse = combinedFallbackProvider
        ? combinedFallbackProvider
        : chainId === SupportedChain.Ethereum
        ? ethersProviderL1
        : null
    }

    if (!providerToUse) {
      console.error('Unsupported chainId for provider')
      return null
    }
    return providerToUse
  }, [connectedChainId, provider, chainId])
}
