import React from 'react'
import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import useCopyClipboard from '../../../hooks/use-copy-clipboard'
import Typography from '@mui/material/Typography'

const CopyButton = ({ text, toCopy }) => {
  const [isCopied, setCopied] = useCopyClipboard()

  const buttonText = isCopied ? 'Copied' : (text || 'Copy')
  return (
    <Button aria-label='close' onClick={() => setCopied(toCopy)}>
      <ButtonText variant='body1'>{buttonText}</ButtonText>
      {isCopied ? <CheckCircleOutlinedIcon fontSize='inherit' /> : <ContentCopyOutlinedIcon fontSize='inherit' />}
    </Button>
  )
}

const Button = styled(IconButton)`
  color: inherit;  
  font-size: inherit;
  &:hover {
    background-color: transparent;
  }
`

const ButtonText = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export default CopyButton
