import React from 'react'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const ErrorMessageView = ({ title, description, sx = null }) => {
  return (
    <Box
      sx={{
        ...(sx
          ? {
              ...sx
            }
          : {
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            })
      }}
    >
      <Text variant='h4' component='h4' color='textPrimary'>
        {title}
      </Text>
      <Text variant='h6' component='h6' color='textSecondary' align='center'>
        {description}
      </Text>
    </Box>
  )
}

const Text = styled(Typography)`
  font-family: Roboto;
  font-weight: 300;
`

export default ErrorMessageView
