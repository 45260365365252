import React from 'react'
import Typography from '@mui/material/Typography'
import { PaintPlusIcon } from '../wallet/paint-icon'
import styled from 'styled-components'
import Button from '@mui/material/Button'

const PaintButton = ({ text, onClick, sx }) => {
  return (
    <RoundedButton
      variant='text'
      aria-label='close'
      onClick={onClick}
      sx={{
        ...sx,
        color: 'inherit',
        fontSize: 'inherit',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }}
      endIcon={<PaintPlusIcon />}
    >
      <Typography variant='body1'>{text}</Typography>
    </RoundedButton>
  )
}

const RoundedButton = styled(Button)`
  border-radius: 20px;
  border: 1px solid;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
`

export default PaintButton
