import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles(theme => ({
  backDrop: {
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(83, 6, 152, 0.2)',
    '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
      backgroundColor: 'rgba(0,0,30,0.85)'
    }
  },
  root: {},
  contentRoot: {
    position: 'relative',
    minHeight: '100%',
    minWidth: '100%',
    margin: '0 auto'
  },
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  },

  dialogContent: {
    backgroundColor: theme.palette.secondary.dark,
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  dialogTitle: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogActions: {
    backgroundColor: theme.palette.secondary.dark,
    paddingRight: '1em',
    paddingBottom: '1em'
  }
}))

export default function StyledImageViewerDialog (props) {
  const classes = useStyles()

  const {
    disableBackdropClick,
    disableEscapeKeyDown,
    fullScreen,
    onClose,
    open,
    scroll,
    maxWidth,
    withCloseButton,
    dialogTitle,
    dialogInformationColumn,
    dialogContentRef,
    dialogContentSx,
    dialogImageContent,
    dialogInformation,
    dialogActions
  } = props

  return (
    <Dialog
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={fullScreen}
      open={open}
      className={classes.root}
      fullWidth
      maxWidth={maxWidth ? maxWidth : 'lg'}
      scroll={scroll}
      PaperProps={{
        style: { borderRadius: 10, height: '100%', width: '100%' }
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          component='h5'
          variant='h5'
          noWrap
          className={clsx(classes.typography)}
          color='textPrimary'
        >
          {dialogTitle}
        </Typography>
        {withCloseButton && (
          <IconButton
            onClick={onClose}
            aria-label='close'
            size='large'
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent
        className={classes.dialogContent}
        scroll={'paper'}
        sx={{
          ...(dialogContentSx
            ? {
                ...dialogContentSx
              }
            : {})
        }}
      >
        {dialogImageContent()}
        {/* <div className={clsx(classes.contentRoot)}>
        </div> */}
      </DialogContent>

      <DialogActions className={clsx(classes.dialogActions)}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'stretch',
            alignContent: 'stretch',
            justifyContent: 'stretch'
          }}
        >
          {dialogInformation && (
            <div
              style={{
                display: 'flex',
                alignSelf: 'stretch',
                justifyContent: 'stretch',
                alignContent: 'flex-start',
                flex: 1,
                flexDirection: dialogInformationColumn ? 'column' : 'row',
                paddingTop: '12px',
                paddingLeft: '16px',
                paddingRight: '16px'
              }}
            >
              {dialogInformation()}
            </div>
          )}
          {dialogActions && (
            <div
              style={{
                // position: 'absolute',
                // bottom: '0px',
                // right: '0px',
                display: 'flex',
                alignContent: 'flex-end',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                width: '100%',
                flexDirection: 'row',
                paddingTop: '16px',
                marginBottom: 0
              }}
            >
              {dialogActions()}
            </div>
          )}
        </div>
      </DialogActions>
    </Dialog>
  )
}
