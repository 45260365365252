import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useActiveWeb3React } from '../../../hooks/web3'
import Typography from '@mui/material/Typography'
import { usePaintDataSource } from '../../../hooks/use-paint-datasource'
import { PaintIcon } from './paint-icon'
import Button from '@mui/material/Button'
import { ethers } from 'ethers'

const ONE_MILLION_WEI = ethers.BigNumber.from(1000000)

const AccountPaintBalance = ({
  textSize = 'h5',
  compact,
  style,
  showPaintText,
  reversed,
  onClick
}) => {
  const { account, chainId } = useActiveWeb3React()
  const { getBalanceForAddress } = usePaintDataSource()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [balance, setBalance] = useState(null)
  useEffect(() => {
    const updateBalance = async () => {
      if (account) {
        const balance = await getBalanceForAddress(account)

        const balanceCorrectDp = ethers.utils.formatUnits(balance, 'ether')
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          ...(compact &&
            ethers.BigNumber.from(balance).gt(ONE_MILLION_WEI) && {
              notation: 'compact'
            })
        })
        setBalance(formatter.format(balanceCorrectDp))
      }
    }
    updateBalance()
  }, [account, chainId, getBalanceForAddress])

  if (onClick && balance) {
    return (
      <Button
        variant="text"
        onClick={onClick}
        {...(!reversed && {
          startIcon: <PaintIcon />
        })}
        {...(reversed && {
          endIcon: <PaintIcon />
        })}
        color="inherit"
        sx={{
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      >
        {account && (
          <Typography
            noWrap
            variant={textSize}
            {...(!reversed && { ml: '0.25em' })}
            {...(reversed && { mr: '0.25em' })}
          >
            {balance}
            {showPaintText && ' PAINT'}
          </Typography>
        )}
      </Button>
    )
  }

  return balance ? (
    <Container style={{ ...style }}>
      {!reversed && <PaintIcon />}
      {account && (
        <Typography
          noWrap
          variant={textSize}
          {...(!reversed && { ml: '0.25em' })}
          {...(reversed && { mr: '0.25em' })}
        >
          {balance}
          {showPaintText && ' PAINT'}
        </Typography>
      )}
      {reversed && <PaintIcon />}
    </Container>
  ) : null
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

export default AccountPaintBalance
