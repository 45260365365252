import React from 'react'
import IconLink from './icon'

const ExternalLink = ({ style, href, text, icon, className }) => {
  return (
    <IconLink
      to={{ pathname: href }}
      target="_blank"
      icon={icon}
      text={text}
      className={className}
      style={style}
    />
  )
}

export default ExternalLink
