import React, { useRef } from 'react'
import Footer from '../components/common/footer'
import AboutContents from '../components/common/about-contents'
import { Box, Typography } from '@mui/material'
import CollabLogosView from '../components/common/collab-logos-view'
import ScrollDownFab from '../components/common/buttons/scroll-down'
import LazyImage from '../components/common/images/LazyImage'
import Fade from '../reveal/in-and-out/Fade'
const LandingPage = () => {
  const detailsRef = useRef(null)
  return (
    <Box>
      <LazyImage
        src={'images/BoringSecurityMurAll.png'}
        lqip={'images/BoringSecurityMurAll_small.png'}
        alt="Boring Security MurAll"
        containerStyle={{
          position: 'fixed',
          zIndex: -1,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
        }}
        style={{
          width: '100vw',
          height: '100vh',
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          margin: 'auto',
          padding: 0,
          position: 'relative',
        }}
      >
        <CollabLogosView
          withGlow
          glowColor={'#000000'}
          style={{
            // backgroundColor: '#ff00ff',
            maxWidth: '900px',
            padding: '48px',
            margin: 0,
          }}
        />
        <Fade>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              align="center"
              gutterBottom
              my={2}
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 100,
                textShadow: '2px 2px 3px black',
              }}
            >
              THE GRID
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              align="center"
              gutterBottom
              my={2}
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 100,
                textShadow: '2px 2px 3px black',
              }}
            >
              Powered by MurAll
            </Typography>
          </Box>
        </Fade>
        <ScrollDownFab
          style={{
            position: 'absolute',
            bottom: '0',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          onClick={() => {
            detailsRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }}
        />
      </Box>
      <AboutContents detailsRef={detailsRef} />
      <Footer />
    </Box>
  )
}

export default LandingPage
