import React, { useEffect, useState } from 'react'
import { Grid, Typography, Link, CardHeader, Avatar } from '@mui/material'
import { useMurAllFramesDataSource } from '../../../hooks/use-murall-frame-datasource'
import { useActiveWeb3React } from '../../../hooks/web3'
import StyledDialog from '../../../js/uicomponents/styled_dialog'
import FrameGridItem from './frame-grid-item'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedFrameId } from '../../../state/slices/frames'

const FramePickerDialog = ({ onClose, open }) => {
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const frames = useSelector(state => {
    return state.frames
  })
  const {
    getBalanceForAddress,
    getOwnedTokenIdsInIndexRange
  } = useMurAllFramesDataSource()
  const [balance, setBalance] = useState(0)
  const [isFetching, setIsFetching] = useState(false)
  const [selectedId, setSelectedId] = useState(frames && frames.assignedFrameId)
  const [tokenIds, setTokenIds] = useState([])
  useEffect(() => {
    if (open && account) {
      initialiseInformation()
    }
  }, [open, account])

  const initialiseInformation = async () => {
    setIsFetching(true)
    const framesBalance = await getBalanceForAddress(account)

    setBalance(parseInt(framesBalance))
    const frames = await getOwnedTokenIdsInIndexRange(
      account,
      0,
      parseInt(framesBalance) - 1
    )

    setTokenIds(frames)
    setIsFetching(false)
  }

  return (
    <StyledDialog
      dialogTitle={
        <>
          <CardHeader
            avatar={<Avatar src={'images/logos/frames.png'} variant='square' />}
            title={'Use Frames with The Grid!'}
            titleTypographyProps={{
              variant: 'h5',
              component: 'h5',
              align: 'left',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 300
            }}
            style={{
              margin: 0,
              padding: 0
            }}
          />

          {balance != 0 && (
            <Typography
              variant='body1'
              component='body1'
              align='center'
              gutterBottom
              my={2}
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 300
              }}
            >
              Select a Frame to display it on The Grid:
            </Typography>
          )}
        </>
      }
      open={open}
      onClose={onClose}
      dialogContent={() => (
        <Grid
          container
          spacing={0}
          direction='column'
          justifyContent='center'
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          {!isFetching && balance == 0 && (
            <Grid item xs={12}>
              <Typography
                variant='h6'
                component='h6'
                align='center'
                gutterBottom
                my={2}
                sx={{
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: 300
                }}
              >
                You don't own any frames! Mint one on{' '}
                <Link
                  href='https://frames.murall.art/'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  our Frames site
                </Link>{' '}
                or buy one on{' '}
                <Link
                  href='https://opensea.io/collection/frames-by-murall'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='secondary'
                >
                  OpenSea
                </Link>
              </Typography>
            </Grid>
          )}
          <Grid
            item
            container
            xs={12}
            spacing={2}
            pt={2}
            style={{ width: '100%', height: '100%' }}
          >
            {isFetching
              ? [...Array(balance)].map((v, index) => (
                  <Grid item xs={6} sm={3} key={index}>
                    <FrameGridItem />
                  </Grid>
                ))
              : tokenIds.map((tokenId, index) => (
                  <Grid item xs={6} sm={3} key={index}>
                    <FrameGridItem
                      title={`Frame #${tokenId}`}
                      tokenId={tokenId}
                      selected={
                        selectedId !== null && selectedId === parseInt(tokenId)
                      }
                      onClick={() => {
                        if (
                          selectedId !== null &&
                          selectedId === parseInt(tokenId)
                        ) {
                          setSelectedId(null)
                          dispatch(setSelectedFrameId(null))
                        } else {
                          setSelectedId(parseInt(tokenId))
                          dispatch(setSelectedFrameId(parseInt(tokenId)))
                        }
                      }}
                    />
                  </Grid>
                ))}
          </Grid>
        </Grid>
      )}
      withCloseButton
      {...(balance != 0 && {
        dialogFooter: () => (
          <Typography
            variant='body1'
            component='body1'
            align='center'
            gutterBottom
            my={2}
            sx={{
              flex: 1,
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 300
            }}
          >
            Mint more on{' '}
            <Link
              href='https://frames.murall.art/'
              target='_blank'
              rel='noopener'
              underline='hover'
              color='secondary'
            >
              our Frames site
            </Link>{' '}
            or buy one on{' '}
            <Link
              href='https://opensea.io/collection/frames-by-murall'
              target='_blank'
              rel='noopener'
              underline='hover'
              color='secondary'
            >
              OpenSea
            </Link>
          </Typography>
        )
      })}
    />
  )
}

export default FramePickerDialog
