import Dialog_class from './../../libs/popup'

class Help_about_class {
  constructor () {
    this.POP = new Dialog_class()
  }

  //about
  about () {
    var email = 'murall@murall.art'

    var settings = {
      title: 'About',
      params: [
        {
          title: '',
          html:
            '<img style="height:64px;" class="about-logo" alt="" src="images/logo-colors.png" />'
        },
        { title: 'Name:', html: '<span class="about-name">The Grid</span>' },
        { title: 'Version:', value: VERSION },
        {
          title: 'Description:',
          value:
            'A Boring Security x MurAll collaboration'
        },
        { title: 'Author:', value: 'Keiron Gulrajani/Toomas Oosalu' },
        {
          title: 'Email:',
          html: '<a href="mailto:' + email + '">' + email + '</a>'
        },
        {
          title: 'GitHub:',
          html:
            '<a href="https://github.com/murall-art/">https://github.com/murall-art</a>'
        },
        {
          title: 'Website:',
          html: '<a href="https://www.murall.art">https://www.murall.art/</a>'
        }
      ]
    }
    this.POP.show(settings)
  }
}

export default Help_about_class
