import React from 'react'
import styled from 'styled-components'

const DeadPage = ({ message }) => {
  const errMessage = message || 'Yikes! You managed to break me! Please refresh the page, or visit from another browser or device.'
  return (
    <MessageWrapper>
      <h2>{errMessage}</h2>
    </MessageWrapper>
  )
}

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
`

export default DeadPage
