import { useMemo } from 'react'
import { useActiveWeb3React } from './web3'
import { SupportedChain } from '../lib/constants'
import { useEthersContractForAddress } from './use-ethers-contract'
import GridNFTAbiL1 from '../contracts/layer1/GridNFT.json'
import MintManagerAbiL1 from '../contracts/layer1/MintManager.json'
import PaintContractAbiL1 from '../contracts/layer1/PaintToken.json'
import PaintContractAbiL2 from '../contracts/layer2/PaintToken.json'
import MurAllFramesContract from '../contracts/layer1/MurAllFrame.json'

// returns null on errors
export const useContract = (
  buildMap,
  overrideAddress = null,
  forceArchiveNode = false
) => {
  const { chainId } = useActiveWeb3React()

  const buildFile = buildMap && buildMap[chainId]
  const networkData = buildFile?.networks[chainId]
  const address = overrideAddress || networkData?.address
  return useEthersContractForAddress({
    abi: buildFile?.abi,
    contractAddress: address,
    targetChainId: chainId,
    forceArchiveNode: forceArchiveNode
  })
}

const generateBuildMap = (layer1Abi, layer2Abi) => {
  return useMemo(() => {
    return {
      [SupportedChain.Ethereum]: layer1Abi,
      [SupportedChain.Ropsten]: layer1Abi,
      [SupportedChain.Rinkeby]: layer1Abi,
      [SupportedChain.Goerli]: layer1Abi,
      [SupportedChain.LocalhostL1]: layer1Abi,
      [SupportedChain.Polygon]: layer2Abi,
      [SupportedChain.Mumbai]: layer2Abi,
      [SupportedChain.LocalhostL2]: layer2Abi
    }
  }, [layer1Abi, layer2Abi])
}

export const useGridNFTContract = (forceArchiveNode = false) => {
  const buildMap = generateBuildMap(GridNFTAbiL1, null)
  return useContract(buildMap, null, forceArchiveNode)
}

export const usePaintContract = (forceArchiveNode = false) => {
  const buildMap = generateBuildMap(PaintContractAbiL1, PaintContractAbiL2)
  return useContract(buildMap, null, forceArchiveNode)
}

export const useMintManagerContract = (forceArchiveNode = false) => {
  const buildMap = generateBuildMap(MintManagerAbiL1, null)
  return useContract(buildMap, null, forceArchiveNode)
}

export const useMurAllFramesContract = (
  address = null,
  forceArchiveNode = false
) => {
  const buildMap = generateBuildMap(MurAllFramesContract, null)
  return useContract(buildMap, address, forceArchiveNode)
}
