import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import Modal from '@mui/material/Modal'
import Content from './content'

import usePrevious from '../../../../hooks/use-previous'

const WalletConnectModal = ({ isOpen, closeModal }) => {
  const { account } = useWeb3React()
  const previousAccount = usePrevious(account)

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && isOpen) {
      closeModal()
    }
  }, [account, previousAccount, isOpen])

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      keepMounted
      BackdropProps={{
        sx: {
          backdropFilter: 'blur(4px)',
          backgroundColor: 'rgba(83, 6, 152, 0.2)',
          '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
            backgroundColor: 'rgba(0,0,30,0.85)'
          }
        }
      }}
    >
      <Wrapper>
        <Content isModalOpen={isOpen} closeModal={closeModal} />
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.main};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  min-width: 320px;
  max-width: 420px;
  max-height: 90vh;
  border-radius: 15px;
  padding: ${({ theme }) => theme.spacing(2)};

  &:focus {
    outline: none;
  }
`

export default WalletConnectModal
