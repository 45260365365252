import { useState, useEffect } from 'react'
const MOBILE_WIDTH_THRESHOLD_PX = 700

const useSmallScreenDetection = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [smallScreen, setSmallScreen] = useState(false)

  useEffect(() => {
    // Handler to call on window resize
    function handleResize () {
      // Set small screen based on hitting threshold
      // setSmallScreen(window.innerWidth <= MOBILE_WIDTH_THRESHOLD_PX)
      setSmallScreen(
        !!navigator.maxTouchPoints ||
          window.innerWidth <= MOBILE_WIDTH_THRESHOLD_PX
      )
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return smallScreen
}

export default useSmallScreenDetection
