import { useMemo } from 'react'
import { getSvgCodeFromFile, replaceIpfsIfNeeded } from '../js/libs/appUtils'
import { useMurAllFramesContract as useMurAllFramesContractForChain } from './use-contract-for-chain'
import { getMurAllFramesContractAddress } from '../js/modules/blockchain/datasource/ContractAddressDataSource'
import { SupportedChain } from '../lib/constants'
import { useEthersSigner } from './use-ethers-signer'
import { ethers } from 'ethers'

export const useMurAllFramesDataSource = () => {
  const contract = useMurAllFramesContractForChain(SupportedChain.Ethereum)
  const signer = useEthersSigner()

  return useMemo(() => {
    const getEndpointForToken = (tokenId, chainId = 1) => {
      return `https://frames-api.murall.art/v1/tokens/${getMurAllFramesContractAddress()}/${tokenId}/metadata?chain=${chainId}`
    }

    const getTraits = tokenId => {
      return contract?.getTraits(tokenId)
    }

    const getFullTokenUriMetadata = async tokenId => {
      let tokenUri = await contract?.tokenURI(ethers.BigNumber.from(tokenId))

      let returnData = await getJsonFromEndpoint(tokenUri)

      return returnData
    }

    const getBaseFrameSvg = tokenId => {
      return getFullTokenUriMetadata(tokenId)
        .then(data =>
          getSvgCodeFromFile(replaceIpfsIfNeeded(data.animation_url))
        )
        .then(svgCode =>
          svgCode.substring(
            svgCode.indexOf('<svg'),
            svgCode.lastIndexOf('</svg>') + 6
          )
        )
        .then(svgCode =>
          svgCode.substring(
            svgCode.indexOf('<style'),
            svgCode.lastIndexOf('</defs>') + 7
          )
        )
    }

    return {
      getTraits,
      getBaseFrameSvg,

      getBalanceForAddress: address => {
        return contract?.balanceOf(address)
      },
      ownerOf: tokenId => {
        return contract?.ownerOf(tokenId)
      },
      name: () => {
        return contract?.name()
      },
      symbol: () => {
        return contract?.symbol()
      },
      totalSupply: () => {
        return contract?.totalSupply()
      },
      getOwnedTokenIdsInIndexRange: (ownerAddress, startIndex, endIndex) => {
        const indexes = [...Array(parseInt(endIndex + 1)).keys()].slice(
          startIndex,
          endIndex + 1
        )

        return Promise.all(
          indexes.map(index =>
            contract?.tokenOfOwnerByIndex(ownerAddress, index)
          )
        )
      },
      getOwnedTokenIdAtIndex: (ownerAddress, index) => {
        return contract?.tokenOfOwnerByIndex(ownerAddress, index)
      },
      transferTokenId: (senderAddress, recipientAddress, tokenId) => {
        return contract
          ?.connect(signer)
          .transferFrom(
            senderAddress,
            recipientAddress,
            ethers.BigNumber.from(tokenId)
          )
          .wait()
      },
      setApproveTokenTransfer: (ownerAddress, spenderAddress, approved) => {
        return contract
          ?.connect(signer)
          .setApprovalForAll(spenderAddress, approved)
          .wait()
      },
      isTokenTransferApproved: (ownerAddress, spenderAddress) => {
        return contract?.isApprovedForAll(ownerAddress, spenderAddress)
      },
      getTokenUri: tokenId => {
        return contract?.tokenURI(tokenId)
      },
      getOffchainRemoteData: async tokenId => {
        let tokenUri = await contract?.tokenURI(ethers.BigNumber.from(tokenId))

        let returnData = await getJsonFromEndpoint(tokenUri)

        return returnData
      },
      getFullTokenUriMetadata
    }
  }, [contract, signer])
}
