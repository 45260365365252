import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
const MAX_WIDTH = 1200
function DefaultContainer(props) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  return (
    <Box
      {...props}
      sx={{
        ...(props.sx
          ? {
              ...props.sx,
            }
          : {}),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: `${MAX_WIDTH}px`,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        position: 'relative',
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: isMobile ? 0 : 16,
        paddingRight: isMobile ? 0 : 16,
      }}
    >
      {props.children}
    </Box>
  )
}

export default DefaultContainer
