import { createSlice } from '@reduxjs/toolkit'

const drawWelcomeSlice = createSlice({
  name: 'drawWelcome',
  initialState: { hide: false },
  reducers: {
    setHideDrawWelcome: (state, action) => {
      state.hide = action.payload
      return state
    }
  }
})

export const { setHideDrawWelcome } = drawWelcomeSlice.actions

export default drawWelcomeSlice.reducer
