import { createSlice } from '@reduxjs/toolkit'

const termsSlice = createSlice({
  name: 'terms',
  initialState: { agreed: false },
  reducers: {
    setAgreed: (state, action) => {
      state.agreed = !!action.payload
      return state
    }
  }
})

export const { setAgreed } = termsSlice.actions

export default termsSlice.reducer
