import React from 'react'
import { useWeb3React } from '@web3-react/core'

import ErrorView from './dead'
import { useEagerConnect, useInactiveListener } from '../../hooks/web3'

const Web3ReactManager = ({ children }) => {
  const { active } = useWeb3React()

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager)

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  if (triedEager && !active && fallbackError) return <ErrorView />

  return children
}

export default Web3ReactManager
