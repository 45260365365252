import config from './../config.js'
import Base_tools_class from './../core/base-tools.js'
import Base_layers_class from './../core/base-layers.js'

class Grid_class extends Base_tools_class {
  constructor (ctx) {
    super()
    this.Base_layers = new Base_layers_class()
    this.ctx = ctx
    this.name = 'grid'
    this.layer = {}
  }

  load () {
    //nothing
  }

  render (ctx, layer) {
    var params = layer.params

    var stroke = params.stroke
    var size = params.size
    var color = layer.color

    ctx.save()
    ctx.strokeStyle = color
    ctx.lineWidth = stroke

    // Box width
    var bw = config.WIDTH
    // Box height
    var bh = config.HEIGHT
    // Padding
    var p = 0

    for (var x = 0; x <= bw; x += size) {
      ctx.moveTo(0.5 + x + p, p)
      ctx.lineTo(0.5 + x + p, bh + p)
    }

    for (var x = 0; x <= bh; x += size) {
      ctx.moveTo(p, 0.5 + x + p)
      ctx.lineTo(bw + p, 0.5 + x + p)
    }

    ctx.stroke()

    ctx.restore()
  }
}
export default Grid_class
