import React from 'react'
import ExternalLink from './external'
import { SupportedChain } from '../../../lib/constants'

export const EntityType = {
  ADDRESS: 'address',
  TRANSACTION: 'transaction',
  TOKEN: 'token',
  BLOCK: 'block'
}

const BASE_URLS = {
  [SupportedChain.Ethereum]: 'https://etherscan.io',
  [SupportedChain.Ropsten]: 'https://ropsten.etherscan.io',
  [SupportedChain.Rinkeby]: 'https://rinkeby.etherscan.io',
  [SupportedChain.Goerli]: 'https://goerli.etherscan.io',
  [SupportedChain.Polygon]: 'https://polygonscan.com',
  [SupportedChain.Mumbai]: 'https://mumbai.polygonscan.com'
}

const getUrl = (chainId, entity, type) => {
  const baseUrl = BASE_URLS[chainId] || 'https://etherscan.io'
  switch (type) {
    case EntityType.ADDRESS:
      return `${baseUrl}/address/${entity}`
    case EntityType.BLOCK:
      return `${baseUrl}/block/${entity}`
    case EntityType.TOKEN:
      return `${baseUrl}/token/${entity}`
    case EntityType.TRANSACTION:
      return `${baseUrl}/tx/${entity}`
    default:
      return baseUrl
  }
}

const ExplorerLink = ({ chainId = SupportedChain.Ethereum, entity, type = EntityType.ADDRESS }) => {
  const url = getUrl(chainId, entity, type)
  return (
    <ExternalLink href={url} text='View on Explorer' />
  )
}

export default ExplorerLink
