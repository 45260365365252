import React from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const CloseButton = ({ onClick, className, sx }) => {
  return (
    <IconButton
      aria-label='close'
      onClick={onClick}
      className={className}
      sx={sx}
    >
      <CloseIcon />
    </IconButton>
  )
}

export default CloseButton
