import { combineReducers } from 'redux'

import network from './slices/network'
import terms from './slices/terms'
import drawWelcome from './slices/drawWelcome'
import frames from './slices/frames'
import events from './slices/events'
import { OpenseaApi } from './opensea'
const rootReducer = combineReducers({
  network,
  terms,
  events,
  drawWelcome,
  frames,
  [OpenseaApi.reducerPath]: OpenseaApi.reducer
})

export default rootReducer
