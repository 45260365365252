import { ethers } from 'ethers'
import { SupportedChain } from '../lib/constants'
const WEBSOCKET_URL = {
  [SupportedChain.Ethereum]:
    'wss://eth-mainnet.ws.alchemyapi.io/v2/JBcsj6b99HP27jZ-QRJ5ekt1PDVXicHS',
  [SupportedChain.Goerli]:
    'wss://eth-goerli.ws.alchemyapi.io/v2/8Vpu96KpWOtMuVVU1VpiTaIu6Nu3LtRn'
}

const getEthersWebsocketProvider = (chainId = SupportedChain.Ethereum) => {
  const url = WEBSOCKET_URL[chainId]
  if (!url) throw new Error(`No websocket url for chainId ${chainId}`)
  const provider = new ethers.providers.WebSocketProvider(url)

  return provider
}

export default getEthersWebsocketProvider
