import React from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import Typography from '@mui/material/Typography'
import { SupportedChain } from '../../../../lib/constants'

const ErrorContent = () => {
  const { account, chainId } = useWeb3React()
  if (!error) return null

  const message = account && chainId !== SupportedChain.Ethereum
    ? 'Please connect to Ethereum mainnet.'
    : 'Error while connecting to your wallet. Try refreshing.'
  return <Typography variant='body1'>{message}</Typography>
}

export default ErrorContent
