import React from 'react'
import { Box, Link } from '@mui/material'
export default function BoringSecurityClickThrough({ style }) {
  return (
    <Link
      underline="none"
      href="https://boringsecurity.com"
      color="white"
      target="_blank"
      rel="noopener"
      {...style}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={'images/logos/BoringSecurityWatermark_long.png'}
          alt="MurAll logo"
          style={{ height: '30px' }}
        />
      </Box>
    </Link>
  )
}
