import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import ErrorMessageView from '../components/common/error-view'
import LoadingSpinner from '../components/common/loader'
import { useGridNFTDataSource } from '../hooks/use-grid-nft-datasource'
import GridNftPreviewDialog from '../components/common/nft/grid-nft-preview-dialog'
import { useActiveWeb3React } from '../hooks/web3'
import { Grid, Typography, Link, Box } from '@mui/material'
import GridCardItem from '../components/common/grid/grid-card-item'
const MAX_WIDTH = 1200

const MyNftsPage = ({ showMurAllLogoSpinner, style }) => {
  const { account } = useActiveWeb3React()

  const [currentSelectedState, setCurrentSelectedState] = useState(null)
  const [currentSelectedStateImage, setCurrentSelectedStateImage] = useState(
    null
  )
  const [gridNftDialogOpen, setGridNftDialogOpen] = useState(false)

  const [balance, setBalance] = useState(0)
  const [tokenIds, setTokenIds] = useState([])
  const [loading, setLoading] = useState(true)
  const {
    getBalanceForAddress,
    getOwnedTokenIdsInIndexRange
  } = useGridNFTDataSource()

  const network = useSelector(state => state.network)

  useEffect(() => {
    if (!network.id || !network.supported) {
      return
    }
    setLoading(true)
    getBalanceForAddress(account)
      .then(balance => {
        setBalance(balance)
        setBalance(parseInt(balance))
        return getOwnedTokenIdsInIndexRange(account, 0, parseInt(balance) - 1)
      })
      .then(tokenIds => {
        setTokenIds(tokenIds)
        console.log('tokenIds', tokenIds)
      })
      .catch(() => {
        setBalance(0)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [network, account, getBalanceForAddress])

  if (loading) return <LoadingSpinner useLogo={showMurAllLogoSpinner} />

  if (network.id && !network.supported)
    return (
      <ErrorMessageView
        title='Network unsupported'
        description='Please select the Ethereum Mainnet or Polygon network'
      />
    )

  return (
    <DefaultContainer>
      <Grid
        container
        spacing={0}
        direction='column'
        justifyContent='center'
        sx={{
          ...style,
          padding: '24px',
          flex: 1,
          width: '100%',
          height: '100%'
        }}
      >
        {!loading && balance == 0 && (
          <Grid item xs={12}>
            <Typography
              variant='h6'
              component='h6'
              align='center'
              gutterBottom
              my={2}
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 300
              }}
            >
              You don't own any Grid NFTs! Mint one or buy one on{' '}
              <Link
                href='https://opensea.io/collection/boring-security-supporter-grid'
                target='_blank'
                rel='noopener'
                underline='hover'
                color='secondary'
              >
                OpenSea
              </Link>
            </Typography>
          </Grid>
        )}
        <Grid
          item
          container
          xs={12}
          spacing={2}
          pb={2}
          style={{ width: '100%', height: '100%' }}
        >
          {loading
            ? [...Array(balance)].map((v, index) => (
                <Grid item xs={6} sm={3} key={index}>
                  <GridCardItem />
                </Grid>
              ))
            : tokenIds.map((tokenId, index) => (
                <Grid item xs={6} sm={4} key={index}>
                  <GridCardItem
                    title={`Grid #${tokenId}`}
                    tokenId={tokenId}
                    onClick={(tokenId, image) => {
                      setCurrentSelectedStateImage(image)
                      setCurrentSelectedState(tokenId)
                      setGridNftDialogOpen(true)
                    }}
                  />
                </Grid>
              ))}
        </Grid>
      </Grid>
      {gridNftDialogOpen && (
        <GridNftPreviewDialog
          dialogTitle={`Grid #${currentSelectedState}`}
          open={gridNftDialogOpen}
          image={currentSelectedStateImage}
          tokenId={currentSelectedState}
          onClose={() => {
            setGridNftDialogOpen(false)
          }}
        />
      )}
    </DefaultContainer>
  )
}

function DefaultContainer (props) {
  return (
    <Box
      {...props}
      sx={{
        ...(props.sx
          ? {
              ...props.sx
            }
          : {}),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: `${MAX_WIDTH}px`,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        padding: 0,
        position: 'relative'
      }}
    >
      {props.children}
    </Box>
  )
}

export default MyNftsPage
