import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

const PulseBorder = styled.div`
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(90deg, ${({ theme }) => theme.palette.primary.main} 35%, ${({ theme }) => theme.palette.secondary.main} 50%, ${({ theme }) => theme.palette.primary.main} 65%);
  background-size: 400% 400%;
  -moz-animation: ${pulse} 10s ease-in-out infinite;
  animation: ${pulse} 10s ease-in-out infinite;
`

export default PulseBorder
