import config from '../../../config.js'
import Base_layers_class from '../../../core/base-layers.js'
import {
  createOffscreenCanvas,
  cropImageFromCanvasContext,
  disableCanvasSmooth
} from '../../../libs/appUtils.js'

class Get_image_for_layer_usecase {
  constructor () {
    this.Base_layers = new Base_layers_class()
  }

  execute (layer = config.layer) {
    const offScreenCanvas = createOffscreenCanvas(config.WIDTH, config.HEIGHT)
    const ctx = offScreenCanvas.getContext('2d')
    disableCanvasSmooth(ctx)
    this.Base_layers.render_object(ctx, layer)
    const fullBase64PngString = offScreenCanvas.toDataURL()
    const positionInformation = cropImageFromCanvasContext(ctx)
    const croppedBase64PngString = offScreenCanvas.toDataURL()

    return { fullBase64PngString, croppedBase64PngString, positionInformation }
  }
}

export default Get_image_for_layer_usecase
