import React from 'react'

import { useActiveWeb3React } from '../../../../hooks/web3'
import PaintButton from '../../buttons/paint-button'
import { Grid, Typography, Box, Link } from '@mui/material'
import {
  addPaintToken,
  launchUniswapForPaintToken
} from '../../../../lib/actions/add-token'
import UniswapButton from '../../buttons/uniswap-button'

const PaintActions = () => {
  const { library, account, chainId } = useActiveWeb3React()
  return (
    <Grid
      container
      direction={'column'}
      spacing={1}
      justifyContent={'space-between'}
      alignItems={'stretch'}
      sx={{
        color: 'text.secondary'
      }}
    >
      <Grid item xs={12}>
        <PaintButton
          sx={{
            width: '100%'
          }}
          text='Add PAINT token to your wallet'
          onClick={() => {
            addPaintToken(library, chainId)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <UniswapButton
          sx={{
            width: '100%'
          }}
          text='Get more PAINT on Uniswap'
          onClick={() => {
            launchUniswapForPaintToken()
          }}
        />
      </Grid>
    </Grid>
  )
}

export default PaintActions
