import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import _ from 'underscore'

const useStyles = makeStyles(theme => ({
  backDrop: {
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(83, 6, 152, 0.2)',
    '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
      backgroundColor: 'rgba(0,0,30,0.85)'
    }
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  dialogContent: {
    padding: 0,
    margin: 0
    // backgroundColor: theme.palette.secondary.dark,
  },
  dialogTitle: {
    // backgroundColor: theme.palette.secondary.dark,
  },
  dialogActions: {
    // backgroundColor: theme.palette.secondary.dark,
  },
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  }
}))

export default function TransparentDialog (props) {
  const classes = useStyles()

  const {
    disableBackdropClick,
    disableEscapeKeyDown,
    fullScreen,
    onClose,
    open,
    scroll,
    maxWidth,
    withCloseButton,
    dialogTitle,
    dialogFooterColumn,
    dialogFooter,
    onPositiveButtonClick,
    positiveButtonTitle,
    onNegativeButtonClick,
    negativeButtonTitle,
    dialogContentRef,
    dialogContentSx,
    dialogContent
  } = props

  const constructActions = () => (
    <>
      {dialogFooter && dialogFooter()}
      {onPositiveButtonClick && (
        <Button
          size='large'
          color='primary'
          onClick={onPositiveButtonClick}
          variant='contained'
        >
          {positiveButtonTitle}
        </Button>
      )}
      {onNegativeButtonClick && (
        <Button
          size='large'
          color='primary'
          onClick={onNegativeButtonClick}
          variant='outlined'
        >
          {negativeButtonTitle}
        </Button>
      )}
    </>
  )
  return (
    <Dialog
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      fullWidth={true}
      scroll={scroll ? scroll : 'paper'}
      maxWidth={maxWidth ? maxWidth : 'md'}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          backgroundImage: 'none'
        }
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
    >
      <DialogTitle className={clsx(classes.transparent, classes.dialogTitle)}>
        {_.isFunction(dialogTitle) ? (
          dialogTitle()
        ) : (
          <Typography
            component='h5'
            variant='h5'
            noWrap
            className={clsx(classes.typography)}
            color='textPrimary'
          >
            {dialogTitle}
          </Typography>
        )}

        {withCloseButton && (
          <IconButton
            onClick={onClose}
            aria-label='close'
            size='large'
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent
        className={clsx(classes.transparent, classes.dialogContent)}
        ref={dialogContentRef}
        sx={{
          ...(dialogContentSx
            ? {
                ...dialogContentSx
              }
            : {})
        }}
      >
        {dialogContent()}
      </DialogContent>
      {(onPositiveButtonClick || dialogFooter) && (
        <DialogActions
          className={clsx(classes.transparent, classes.dialogActions)}
        >
          {dialogFooterColumn ? (
            <div
              style={{
                position: 'relative',
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'stretch',
                alignContent: 'stretch',
                justifyContent: 'stretch'
              }}
            >
              {constructActions()}
            </div>
          ) : (
            constructActions()
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}
