import React, { StrictMode, createContext } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Web3ReactProvider } from '@web3-react/core'
import { CookiesProvider } from 'react-cookie'
import store from './state/store'
import Updater from './state/updater'
import App from './App'
import getEthersProvider from './hooks/getEthersProvider'
import getEthersWebsocketProvider from './hooks/getEthersWebsocketProvider'
import { PRIORITIZED_CONNECTORS } from './lib/connectors/index'

export const EthersL1Context = createContext()
export const EthersL1WebsocketContext = createContext()

const element = document.getElementById('loading_screen')
element.remove()

ReactDOM.render(
  <Provider store={store()}>
    <StrictMode>
      <EthersL1Context.Provider value={getEthersProvider()}>
        <EthersL1WebsocketContext.Provider value={getEthersWebsocketProvider()}>
          <Web3ReactProvider
            connectors={Object.values(PRIORITIZED_CONNECTORS).map(connector => [
              connector.connector,
              connector.hooks
            ])}
          >
            <Updater />
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </Web3ReactProvider>
        </EthersL1WebsocketContext.Provider>
      </EthersL1Context.Provider>
    </StrictMode>
  </Provider>,
  document.getElementById('top_buttons_container')
)
