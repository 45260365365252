import React from 'react'
import styled from 'styled-components'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import Typography from '@mui/material/Typography'
import CloseButton from 'components/common/buttons/close'

const WalletModalHeader = ({ onClose, showBackButton, onBackClick }) => {
  const { account, error } = useWeb3React()

  return (
    <Container>
      {showBackButton ? (
        <Back onClick={onBackClick} />
      ) : (
        <Typography variant='h6'>{getHeaderMessage(account, error)}</Typography>
      )}
      <CloseButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8
        }}
      />
    </Container>
  )
}

const getHeaderMessage = (account, error) => {
  if (error) {
    return error instanceof UnsupportedChainIdError
      ? 'Unsupported network'
      : 'Error'
  } else if (account) {
    return 'Account'
  } else {
    return 'Select a wallet'
  }
}

const Back = ({ onClick }) => {
  return (
    <BackButton variant='h6' onClick={onClick}>
      Back
    </BackButton>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  button {
    margin-left: auto;
  }
`

const BackButton = styled(Typography)`
  cursor: pointer;
`

export default WalletModalHeader
