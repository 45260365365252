import React from 'react'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  GRID_NUM_COLUMNS,
  GRID_NUM_ROWS,
} from '../../../lib/constants'
import FramesView from './frame-view'

export default function FramesWrapperView({
  tokenId,
  width = CANVAS_WIDTH * GRID_NUM_COLUMNS,
  height = CANVAS_HEIGHT * GRID_NUM_ROWS,
  children,
  style,
}) {
  if (tokenId == null || tokenId == undefined) {
    return (
      <div
        style={{
          ...style,
        }}
      >
        {children}
      </div>
    )
  }

  return (
    <FramesView
      style={style}
      tokenId={tokenId}
      width={width}
      height={height}
      children={children}
    />
  )
}
