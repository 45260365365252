import Web3 from 'web3'
import { SupportedChain } from '../../lib/constants'

export const RPC_INFO = {
  [SupportedChain.Ethereum]: {
    rpcUrl:
      'wss://eth-mainnet.alchemyapi.io/v2/JBcsj6b99HP27jZ-QRJ5ekt1PDVXicHS',
  },
  [SupportedChain.Rinkeby]: {
    rpcUrl:
      'wss://eth-rinkeby.alchemyapi.io/v2/puDsFO1WUH-LWm51l3K94J4PQTEZve9H',
  },
}

const getWeb3L1 = (chainId = SupportedChain.Ethereum) => {
  const url = RPC_INFO[chainId].rpcUrl
  const provider = new Web3.providers.WebsocketProvider(url)

  return new Web3(provider)
}

export default getWeb3L1
