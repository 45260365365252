import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import useStyles from './styles'
import { useGridNFTDataSource } from '../../../hooks/use-grid-nft-datasource'
import { useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'

const PLACEHOLDER = 'images/logos/bs_x_murall_160.jpg'

export default function SvgGridState({
  tokenId,
  x,
  y,
  width,
  height,
  shouldHide,
  animationDuration,
  filter,
  useGlitchEffect,
  onClick
}) {
  const [image, setImage] = useState(null)
  const [shouldShowNumbers, setShouldShowNumbers] = useState(false)
  const [hovering, setHovering] = useState(false)
  const [freeForAll, setFreeForAll] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const { getCurrentGridContents, isTokenInFreeForAllMode } =
    useGridNFTDataSource()

  const events = useSelector(state => state.events)

  useEffect(() => {
    const paintedEvents = events?.paintedEvents
    if (!paintedEvents || !paintedEvents.length) return
    paintedEvents.map(event => {
      if (parseInt(event.tokenId) === tokenId) {
        setUpdating(true)
        setImage(event.image)
      }
    })
  }, [events?.paintedEvents?.length])

  useEffect(() => {
    setImage(null)
    setShouldShowNumbers(false)
    setIsLoading(true)
    getCurrentGridContents(tokenId)
      .then(tokenInfo => {
        setUpdating(true)
        setShouldShowNumbers(false)
        setImage(tokenInfo.image)
      })
      .catch(err => {
        console.error(err)
        setUpdating(true)
        setImage(PLACEHOLDER)
        setShouldShowNumbers(true)
      })
      .finally(() => {
        setIsLoading(false)
      })

    isTokenInFreeForAllMode(tokenId)
      .then(freeForAll => {
        setFreeForAll(freeForAll)
      })
      .catch(err => {
        console.error(err)
        setFreeForAll(false)
      })
  }, [tokenId])

  const classes = useStyles({
    animationDuration
  })

  return (
    <g transform={`translate(${x} ${y})`}>
      <image
        className={clsx(
          'pixelated-image',
          {
            [classes.hide]: shouldHide
          },
          {
            [classes.show]: !shouldHide
          },
          {
            [classes.hoverEnter]: hovering && !freeForAll
          },
          {
            [classes.hoverLeave]: !hovering && !freeForAll
          },
          {
            [classes.hoverEnterFreeForAll]: hovering && freeForAll
          },
          {
            [classes.hoverLeaveFreeForAll]: !hovering && freeForAll
          }
        )}
        height={height}
        width={width}
        xlinkHref={image}
        href={image}
        onClick={() => onClick(tokenId, image)}
        onMouseEnter={() => {
          setHovering(true)
        }}
        onMouseOut={() => {
          setHovering(false)
        }}
        onMouseLeave={() => {
          setHovering(false)
        }}
        style={{
          ...(filter && {
            filter: filter
          }),
          ...(hovering &&
            freeForAll && {
              fill: 'rgb(231, 26, 246)'
            })
        }}
      />
      {isLoading && (
        <foreignObject width={width} height={height}>
          <Skeleton
            variant="rectangular"
            width={width}
            height={height}
            animation="wave"
            style={{ transform: 'translate(0, 0)' }}
          />
        </foreignObject>
      )}
      {!shouldHide && useGlitchEffect && (
        <image
          className={clsx('pixelated-image', classes.glitch1)}
          height={height}
          width={width}
          href={image}
        />
      )}
      {updating && (
        <image
          className={clsx('pixelated-image', classes.glitch1)}
          height={height}
          width={width}
          href={image}
          onAnimationEnd={() => {
            setUpdating(false)
          }}
        />
      )}
      {shouldShowNumbers && (
        <text
          x="80"
          y="30"
          color="textSecondary"
          fill="white"
          dominantBaseline="middle"
          textAnchor="middle"
          filter="drop-shadow( 0px 6px 2px rgba(0, 0, 0, .7))"
          style={{ fontFamily: 'Roboto', fontWeight: 100, fontSize: '35px' }}
        >
          #{tokenId}
        </text>
      )}
    </g>
  )
}
