import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx' // a utility for constructing className conditionally
import '../../../css/layout.css'

function LazyImage (props) {
  const [loaded, setLoaded] = useState(false)
  const imgRef = useRef()
  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      console.log('image already loaded')
      setLoaded(true)
    }
  }, [])
  useEffect(() => {
    console.log('loaded', loaded)
  }, [loaded])
  return (
    <div
      style={{
        ...(props.containerStyle
          ? {
              ...props.containerStyle
            }
          : {})
      }}
    >
      <img
        style={{
          ...(props.style
            ? {
                ...props.style
              }
            : {})
        }}
        className={clsx('lqip', loaded && 'lqipLoaded', props.className)}
        src={props.lqip}
        alt={props.alt}
        aria-hidden='true'
      />
      <img
        style={{
          ...(props.style
            ? {
                ...props.style
              }
            : {})
        }}
        loading='lazy'
        src={props.src}
        alt={props.alt}
        ref={imgRef}
        onLoad={() => setLoaded(true)}
        className={clsx('lazySource', loaded && 'lazyLoaded', props.className)}
      />
    </div>
  )
}
export default LazyImage
