import { ConnectionType, getConnection } from '../connectors/index'
import { CHAIN_INFO } from '../constants'

const switchToNetwork = async ({ connector, chainId }) => {
  if (!connector) return

  if (
    connector === getConnection(ConnectionType.WALLET_CONNECT).connector ||
    connector === getConnection(ConnectionType.NETWORK).connector
  ) {
    await connector.activate(chainId)
  } else {
    const chainInfo = CHAIN_INFO[chainId]
    const addChainParameter = {
      chainId,
      chainName: chainInfo.label,
      rpcUrls: [...chainInfo.rpcUrls],
      nativeCurrency: chainInfo.nativeCurrency,
      blockExplorerUrls: [chainInfo.explorer]
    }
    await connector.activate(addChainParameter)
  }
}

export default switchToNetwork
