import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import ExternalLink from '../links/external'
import InternalLink from '../links/internal'
import PulseBorder from '../pulse-border'

import TwitterIcon from '@mui/icons-material/Twitter'
import DiscordLogo from '../../../../images/logos/discord.svg'
import TelegramIcon from '@mui/icons-material/Telegram'

const MurAllSocials = ({ sx }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isLandscape = useMediaQuery('(orientation: landscape)')
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        sx={{
          ...sx,
          width: '100%',
          pl: 1.5,
          pr: 1.5,
        }}
      >
        <Grid item xs={4}>
          <img
            src={'images/MurAll_LogoB_L1WHITE.png'}
            // src={'images/logos/MurAllGRID.png'}
            alt="MurAll logo"
            style={{ height: '20px' }}
          />
        </Grid>
        <Grid item xs={8}>
          <SocialsWrapper>
            <ExternalLink
              href="https://twitter.com/MurAll_art"
              icon={<TwitterIcon mx={0.5} fontSize="small" />}
            />
            <ExternalLink
              href="https://discord.murall.art"
              icon={<CustomSocialIcon src={DiscordLogo} alt="Discord" />}
            />

            <ExternalLink
              href="https://t.me/MurALLart"
              icon={<TelegramIcon mx={0.5} fontSize="small" />}
            />
          </SocialsWrapper>
        </Grid>
      </Grid>
    </>
  )
}

const CustomSocialIcon = ({ src, alt }) => {
  return (
    <Icon mr={1} ml={0.5}>
      <img src={src} alt={alt} />
    </Icon>
  )
}

const SocialsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Border = styled(PulseBorder)`
  height: 1px;
  bottom: auto;
`

export default MurAllSocials
