import { useState, useEffect, useCallback } from 'react'
import { useMintManagerContract } from '../use-contract'
import { ethers } from 'ethers'

const MINT_STAGE_PUBLIC = ethers.BigNumber.from(2)

export const useCheckPublicMintOpen = (immediate = true) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState(null)

  const mintManagerContract = useMintManagerContract()

  const execute = useCallback(async () => {
    if (!mintManagerContract) return

    setIsLoading(true)
    setIsOpen(false)
    setError(null)

    try {
      const mintStage = await mintManagerContract?.mintMode()

      setIsOpen(ethers.BigNumber.from(mintStage).eq(MINT_STAGE_PUBLIC))
    } catch (err) {
      console.error('Error checking mint stage', err)
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }, [mintManagerContract])

  useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])
  return { execute, isLoading, isOpen, error }
}

export default useCheckPublicMintOpen
