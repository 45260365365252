import { ethers } from 'ethers'
import { SupportedChain } from '../lib/constants'

const RPC_LIST = {
  [SupportedChain.Ethereum]: [
    'https://eth-mainnet.g.alchemy.com/v2/JBcsj6b99HP27jZ-QRJ5ekt1PDVXicHS',
    'https://mainnet.infura.io/v3/85243453e4734c438e6ef8031118dd86',
    'https://rpc.ankr.com/eth'
  ],
  [SupportedChain.Goerli]: [
    'https://eth-goerli.g.alchemy.com/v2/8Vpu96KpWOtMuVVU1VpiTaIu6Nu3LtRn',
    'https://rpc.ankr.com/eth_goerli',
    'https://endpoints.omniatech.io/v1/eth/goerli/public'
  ]
}

const getEthersProvider = (
  chainId = SupportedChain.Ethereum,
  priorityProvider
) => {
  const urlList = RPC_LIST[chainId]
  if (!urlList) {
    console.error('Unsupported chainId ' + chainId)
    return null
  }

  const stallTimeout = 5 * 1000
  const options = {
    timeout: 30 * 1000,
    throttleLimit: 1
  }
  const quorum = 2

  const providerList = urlList.map(
    url => new ethers.providers.StaticJsonRpcProvider({ url: url, ...options })
  )
  priorityProvider && providerList.unshift(priorityProvider)

  const fallbackProviderParams = []
  for (let i = 0; i < providerList.length; i++) {
    const params = {
      provider: providerList[i],
      priority: i + 1,
      weight: 1,
      stallTimeout
    }
    fallbackProviderParams.push(params)
  }

  const provider = new ethers.providers.FallbackProvider(
    fallbackProviderParams,
    quorum
  )

  return provider
}

export default getEthersProvider
