import React from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'

import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'

const useStyles = base64Png =>
  makeStyles(theme => ({
    root: {
      width: '256px !important',
      height: '256px !important'
    },
    bgImage: {
      backgroundColor: '#fddc6c',
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundBlendMode: `multiply, normal, normal`,
      backgroundImage: `
      radial-gradient(circle at top right, #cccccc, #4a4a4a, #212121),
      url(${base64Png}),
      linear-gradient(to right, #26262B, #26262B)
    `,
      display: 'flex',
      flexDirection: 'column'
    },
    typography: {
      fontFamily: 'Roboto',
      fontWeight: 100
    },
    actionRoot: {
      padding: '8px 8px 8px 16px',
      backgroundColor: '#00ff00'
    },
    icons: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignContent: 'flex-start',
      justifyContent: 'flex-end'
    },
    closeButton: {
      padding: '8px 8px'
    }
  }))

const PaintedEventSnackMessage = ({ event, onClose } = {}) => {
  const classes = useStyles(event.image)()

  return (
    <div className={classes.root}>
      <Card className={clsx('pixelated-image', classes.bgImage)}>
        <CardHeader
          action={
            <IconButton
              className={classes.closeButton}
              onClick={onClose}
              size='large'
            >
              <CloseIcon />
            </IconButton>
          }
          title='New drawing'
        />
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignContent: 'flex-start',
            justifyContent: 'flex-end',
            paddingTop: '0px',
            paddingLeft: '8px',
            paddingBottom: '8px',
            paddingRight: '8px'
          }}
        >
          <Typography
            component='h5'
            variant='h5'
            noWrap
            className={clsx(classes.typography)}
            color='textPrimary'
          >
            Grid Position #{event.tokenId}
          </Typography>

          <Typography
            component='span'
            variant='subtitle2'
            noWrap
            className={clsx(classes.inline, classes.typography)}
            color={'textSecondary'}
            style={{
              width: '100%'
            }}
          >
            {event.artist}
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
}

export default PaintedEventSnackMessage
