import { useState, useEffect, useCallback } from 'react'
import { useGridNFTContract, useMintManagerContract } from '../use-contract'
import { useEthersSigner } from '../use-ethers-signer'
import { ethers } from 'ethers'

export const useMint = (amount, account, immediate = true) => {
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)

  const gridContract = useGridNFTContract()
  const mintManagerContract = useMintManagerContract()
  const signer = useEthersSigner()
  const execute = useCallback(async () => {
    if (!gridContract) return

    setIsLoading(true)
    setValue(null)
    setError(null)

    try {
      const publicSalePrice = await mintManagerContract?.mintPricePublic()

      const mintCost = ethers.BigNumber.from(publicSalePrice).mul(
        ethers.BigNumber.from(amount)
      )

      const result = await gridContract
        ?.connect(signer)
        .mint(amount, { value: mintCost })

      setValue(result)
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }, [gridContract, mintManagerContract, amount, account, signer])

  useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])
  return { execute, isLoading, value, error }
}

export default useMint
