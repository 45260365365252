import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const NavLinks = () => {
  return (
    <Container>
      <LinksWrapper>
        <NavLink to='/state'>
          <LinkItem>The Grid</LinkItem>
        </NavLink>
        <NavLink to='/mint'>
          <LinkItem>Mint</LinkItem>
        </NavLink>
        <NavLink to='/faq'>
          <LinkItem>FAQ</LinkItem>
        </NavLink>
      </LinksWrapper>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items-center;
`

const LinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
`

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
`

const LinkItem = styled.li`
  height: 100%;
  padding: 0 1.1em;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-top: 3px solid transparent;
  transition: all 220ms ease-in-out;

  &:hover {
    border-top: 3px solid ${({ theme }) => theme.palette.primary.main};
  }
`

export default NavLinks
