import React, { useEffect, useMemo, useState } from 'react'
import { useMurAllFramesDataSource } from '../../../hooks/use-murall-frame-datasource'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../../../lib/constants'

const FRAME_RIM_HEIGHT = 125
const FRAME_RIM_WIDTH = 250
const CORNER_PIECE_HEIGHT = 250
const CORNER_PIECE_ID = 'cornerdetail'
const TILE_PIECE_ID = 'Pattern'
const IMAGE_HEIGHT = 1024
const IMAGE_WIDTH = 1024
const ANIMATION_ID = 'effect'
const PLAQUE_ID = 'plaque'
const PLAQUE_HEIGHT = 125
const PLAQUE_WIDTH = 280

const getFullFrameSvg = (
  imageHeight = IMAGE_HEIGHT,
  imageWidth = IMAGE_WIDTH,
  baseSvgString,
  children
) => {
  const percentBigger = imageHeight / IMAGE_HEIGHT
  imageHeight = imageHeight / percentBigger
  imageWidth = imageWidth / percentBigger

  const { frameHeight, frameWidth } = calculateFrameDimensions(
    imageHeight,
    imageWidth
  )

  return (
    <svg
      viewBox={`0 0 ${frameWidth} ${frameHeight}`}
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      {useMemo(() => {
        return baseSvgString ? (
          <svg
            {...(baseSvgString && {
              dangerouslySetInnerHTML: { __html: baseSvgString }
            })}
          />
        ) : null
      }, [baseSvgString])}
      {constructCenterContents(
        children,
        frameWidth,
        frameHeight,
        FRAME_RIM_HEIGHT
      )}
      {useMemo(() => {
        return (
          <>
            {constructTop(frameWidth, frameHeight, TILE_PIECE_ID)}
            {constructBottom(frameWidth, frameHeight, TILE_PIECE_ID)}
            {constructLeft(frameWidth, frameHeight, TILE_PIECE_ID)}
            {constructRight(frameWidth, frameHeight, TILE_PIECE_ID)}
            {constructTopLeftCorner(frameWidth, frameHeight, CORNER_PIECE_ID)}
            {constructTopRightCorner(frameWidth, frameHeight, CORNER_PIECE_ID)}
            {constructBottomLeftCorner(
              frameWidth,
              frameHeight,
              CORNER_PIECE_ID
            )}
            {constructBottomRightCorner(
              frameWidth,
              frameHeight,
              CORNER_PIECE_ID
            )}
            {constructPlaque(frameWidth, frameHeight)}
          </>
        )
      }, [frameWidth, frameHeight])}
    </svg>
  )
}

const calculateFrameDimensions = (imageHeight, imageWidth) => {
  const frameHeight = imageHeight + FRAME_RIM_HEIGHT * 2
  const frameWidth = imageWidth + FRAME_RIM_HEIGHT * 2
  return {
    frameHeight,
    frameWidth
  }
}
const constructCenterContents = (children, width, height, rimHeight) => {
  return (
    <foreignObject
      x={`${rimHeight}`}
      y={`${rimHeight}`}
      height={`${height - FRAME_RIM_WIDTH}`}
      width={`${width - FRAME_RIM_WIDTH}`}
    >
      {children}
    </foreignObject>
  )
}

const constructTop = (width, height, style) => {
  return (
    <g transform='translate(0,0)'>
      {constructTilePiece(width, height, style, true)}
    </g>
  )
}
const constructBottom = (width, height, style) => {
  return (
    <g transform={`translate(${width},${height} ) rotate(180 0 0)`}>
      {constructTilePiece(width, height, style, true)}
    </g>
  )
}

const constructLeft = (width, height, style) => {
  return (
    <g transform={`translate(0,${height}) rotate(270 0 0)`}>
      {constructTilePiece(width, height, style, false)}
    </g>
  )
}

const constructRight = (width, height, style) => {
  return (
    <g transform={`translate(${width},0) rotate(90 0 0)`}>
      {constructTilePiece(width, height, style, false)}
    </g>
  )
}

const constructTopLeftCorner = (width, height, style) => {
  return (
    <g transform='translate(0,0)' style={{ overflow: 'visible' }}>
      {constructCornerPiece(style)}
    </g>
  )
}
const constructTopRightCorner = (width, height, style) => {
  return (
    <g
      transform={`translate(${width},0)  rotate(90 0 0)`}
      style={{ overflow: 'visible' }}
    >
      {constructCornerPiece(style)}
    </g>
  )
}

const constructBottomLeftCorner = (width, height, style) => {
  return (
    <g
      transform={`translate(0,${height})  rotate(-90 0 0)`}
      style={{ overflow: 'visible' }}
    >
      {constructCornerPiece(style)}
    </g>
  )
}
const constructBottomRightCorner = (width, height, style) => {
  return (
    <g
      transform={`translate(${width},${height}) rotate(180 0 0)`}
      style={{ overflow: 'visible' }}
    >
      {constructCornerPiece(style)}
    </g>
  )
}

const constructCornerPiece = style => {
  return (
    <use
      x='0'
      y='0'
      width={`${CORNER_PIECE_HEIGHT}`}
      height={`${CORNER_PIECE_HEIGHT}`}
      xlinkHref={`#${style}`}
      style={{ overflow: 'visible' }}
    />
  )
}

const constructTilePiece = (width, height, style, horizontal = false) => {
  return (
    <rect
      x={`${FRAME_RIM_HEIGHT}`}
      y='0'
      width={`${
        horizontal ? width - FRAME_RIM_WIDTH : height - FRAME_RIM_WIDTH
      }`}
      height={`${FRAME_RIM_HEIGHT}`}
      style={{ fill: `url(#${style})`, filter: `url(#${ANIMATION_ID})` }}
    />
  )
}

const constructPlaque = (width, height) => {
  return (
    <g
      transform={`translate(${width / 2 - PLAQUE_WIDTH / 2},${height -
        PLAQUE_HEIGHT})`}
    >
      <use
        x='0'
        y='0'
        width={`${PLAQUE_WIDTH}`}
        height={`${PLAQUE_HEIGHT}`}
        xlinkHref={`#${PLAQUE_ID}`}
      />
    </g>
  )
}

export default function FramesView ({
  tokenId,
  width = CANVAS_WIDTH,
  height = CANVAS_HEIGHT,
  children,
  style
}) {
  const { getBaseFrameSvg } = useMurAllFramesDataSource()

  const [baseFrameSvgString, setBaseFrameSvgString] = useState(null)

  useEffect(() => {
    if (tokenId != null || tokenId != undefined) {
      getBaseFrameSvg(tokenId).then(svg => {
        setBaseFrameSvgString(svg)
      })
    }
  }, [setBaseFrameSvgString, tokenId])

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      style={{
        ...style
      }}
    >
      {getFullFrameSvg(height, width, baseFrameSvgString, children)}
    </svg>
  )
}
