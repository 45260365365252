import config from './../../config.js'
import Base_gui_class from './../../core/base-gui.js'
import Base_layers_class from './../../core/base-layers.js'
import Helper_class from './../../libs/helpers.js'
import Dialog_class from './../../libs/popup.js'

/**
 * manages files / new
 *
 * @author ViliusL
 */
class File_new_class {
  constructor () {
    this.Base_gui = new Base_gui_class()
    this.Base_layers = new Base_layers_class()
    this.POP = new Dialog_class()
    this.Helper = new Helper_class()
  }

  new () {
    window.State.save()

    this.new_handler()
  }

  new_handler () {
    var width = parseInt(config.WIDTH)
    var height = parseInt(config.HEIGHT)

    var save_resolution = true
    var transparency = true

    if (transparency == true) config.TRANSPARENCY = true
    else config.TRANSPARENCY = false

    config.ZOOM = 1
    config.WIDTH = parseInt(width)
    config.HEIGHT = parseInt(height)
    this.Base_gui.prepare_canvas()

    //prepare layers
    this.Base_layers.reset_layers()
    this.Base_layers.init_zoom_lib()
    this.Base_layers.insert({})
    config.need_render = true

    //reset EXIF
    config.layer._exif = null
  }
}

export default File_new_class
