import React from 'react'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import ExternalLink from '../../links/external'

const WalletOption = ({ id, link, onClick, active, title, icon, description }) => {
  const element = (
    <Container id={id} onClick={onClick} clickable={onClick && !active}>
      {active && <CircleWrapper><GreenCircle /></CircleWrapper>}
      <Titles>
        <Typography variant='h6'>{title}</Typography>
        {description && <Typography variant='caption'>{description}</Typography>}
      </Titles>
      <IconWrapper>
        <img src={icon} alt={'Icon'} />
      </IconWrapper>
    </Container>
  )
  if (link) {
    return <ExternalLink href={link}>{element}</ExternalLink>
  }
  return element
}

const Container = styled.div`
  display: flex;  
  width: 100%;
  background-color: ${({ theme, clickable }) => !clickable ? theme.colors.bg.lightest : theme.colors.bg.lighter};
  border: 1px solid ${({ theme, clickable }) => clickable ? theme.colors.bg.lightest : theme.palette.primary.main};
  border-radius: 15px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1.5)} ${({ theme }) => theme.spacing(2)};

  &:hover {
    border: ${({ theme, clickable }) => (clickable ? `1px solid ${theme.palette.primary.main}` : '')};
    cursor: ${({ clickable }) => (clickable ? 'pointer' : '')};
  }
`

const IconWrapper = styled.div`
  align-items: center;
  justify-content: center;
  & > img,
  span {
    height: 24px;
    width: 24px;
  }
  align-items: flex-end;
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
`

const GreenCircle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &:first-child {
    height: 8px;
    width: 8px;
    margin-right: 8px;
    background-color: ${({ theme }) => theme.colors.named.green};
    border-radius: 50%;
  }
`

const CircleWrapper = styled.div`
  color: ${({ theme }) => theme.colors.named.green};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Titles = styled.div`
`

export default WalletOption
