import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import CollabLogosView from './collab-logos-view'
export default function PoweredByMurAllView ({ style }) {
  return (
    <Link
      underline='none'
      href='https://murall.art'
      color='white'
      target='_blank'
      rel='noopener'
      {...style}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography
          variant='h6'
          align='justify'
          component='p'
          noWrap
          mr={0.5}
          sx={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 300,
            textShadow: '2px 2px 5px black'
          }}
        >
          Powered by
        </Typography>
        <img
          // src={'images/logos/murall/l1.svg'}
          src={'images/MurAll_LogoB_L1WHITE.png'}
          alt='MurAll logo'
          style={{ height: '30px' }}
        />
      </Box>
    </Link>
  )
}
