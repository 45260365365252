import React from 'react'
import styled from 'styled-components'
import CopyButton from '../../buttons/copy'
import ExplorerLink from '../../links/explorer'
import { useActiveWeb3React } from '../../../../hooks/web3'

const AddressActions = () => {
  const { account, chainId } = useActiveWeb3React()
  return (
    <Container>
      <CopyButton text='Copy Address' toCopy={account} />
      <ExplorerLink chainId={chainId} entity={account} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  color: ${({ theme }) => theme.palette.text.secondary};
`

export default AddressActions
