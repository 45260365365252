import React, { useEffect, useRef, useState } from 'react'
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  GRID_NUM_COLUMNS,
} from '../../../lib/constants'
import { gsap } from 'gsap'
import { startPixellateAnimation } from './animations'
import SvgGridState from './svg-grid-state'

export default function SvgGridViewer({
  style,
  totalSupply,
  selectedStateIndex,
  animationDuration,
  useGlitchEffect,
  onGridStateClick,
}) {
  const pixellateGlitchFilter = useRef(null)
  const currentPixellateGlitchAnim = useRef(null)

  const [isLocating, setLocating] = useState(false)
  const timeline = gsap.timeline()

  useEffect(() => {
    if (isLocating) {
      startPixellateAnimation(
        timeline,
        animationDuration,
        pixellateGlitchFilter,
        currentPixellateGlitchAnim,
        () => {
          setLocating(false)
        }
      )
    }
  }, [isLocating])

  if (!totalSupply || totalSupply === 0) {
    return null
  }
  // height up to 6 rows based on total supply, row every 6 grid pieces
  const rows = Math.ceil(totalSupply / GRID_NUM_COLUMNS)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      style={{
        ...(style && {
          ...style,
        }),
      }}
    >
      <defs>
        <filter colorInterpolationFilters="sRGB" id="gapfix">
          <feComponentTransfer id="feComponentTransfer870">
            <feFuncR id="feFuncR872" type="identity" />
            <feFuncG id="feFuncG874" type="identity" />
            <feFuncB id="feFuncB876" type="identity" />
            <feFuncA
              id="feFuncA878"
              type="discrete"
              amplitude="2.3331705729166665"
              tableValues="0 1"
            />
          </feComponentTransfer>
        </filter>
        <filter id="pixelate" x="0" y="0" width="200%" height="200%">
          <feTurbulence
            id="animation"
            type="fractalNoise"
            baseFrequency="0.00001 9.9999999"
            numOctaves="1"
            result="warp"
          >
            {useGlitchEffect && (
              <animate
                attributeName="baseFrequency"
                from="0.00001 9.9999"
                to="0.00001 0.001"
                dur="2s"
                repeatCount="indefinite"
                restart="always"
              />
            )}
          </feTurbulence>
          <feDisplacementMap
            id={'glitchelate'}
            ref={pixellateGlitchFilter}
            xChannelSelector="R"
            yChannelSelector="G"
            scale="0"
            in="SourceGraphic"
            in2="warpOffset"
          ></feDisplacementMap>
        </filter>
      </defs>
      <svg
        viewBox={`0 0 ${CANVAS_WIDTH * GRID_NUM_COLUMNS} ${
          CANVAS_HEIGHT * rows
        }`}
        style={{ shapeRendering: 'crispEdges' }}
      >
        <g>
          {[...Array(totalSupply)].map((v, index) => (
            <SvgGridState
              key={index}
              tokenId={index}
              animationDuration={`${animationDuration}ms`}
              shouldHide={false}
              x={(index % GRID_NUM_COLUMNS) * CANVAS_WIDTH}
              //increase y every 6 rows
              y={Math.floor(index / GRID_NUM_COLUMNS) * CANVAS_HEIGHT}
              height={CANVAS_WIDTH}
              width={CANVAS_HEIGHT}
              filter={
                index === selectedStateIndex && isLocating
                  ? 'url(#pixelate)'
                  : 'url(#gapfix)'
              }
              useGlitchEffect={index === selectedStateIndex && isLocating}
              onClick={(tokenId, image) => {
                setLocating(true)
                onGridStateClick && onGridStateClick(tokenId, image)
              }}
            />
          ))}
        </g>
      </svg>
    </svg>
  )
}
