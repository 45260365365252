import config from './../../config.js'
import Base_layers_class from './../../core/base-layers.js'
import Dialog_class from './../../libs/popup.js'

class Tools_grid_class {
  constructor () {
    this.POP = new Dialog_class()
    this.Base_layers = new Base_layers_class()
  }

  grid () {
    var _this = this

    var settings = {
      title: 'Grid',
      preview: true,
      on_change: function (params, canvas_preview, w, h) {
        var target = Math.min(w, h)
        params.size = Math.round((target / 100) * params.size)
        _this.preview_grid(params, canvas_preview, w, h)
      },
      params: [
        { name: 'color', title: 'Color:', value: config.COLOR, type: 'color' },
        {
          name: 'stroke',
          title: 'Line stroke size:',
          value: '1',
          range: [1, 10]
        },
        { name: 'size', title: 'Square size:', value: '16', range: [1, 100] }
      ],
      on_finish: function (params) {
        _this.add_grid(params)
      }
    }
    this.POP.show(settings)
  }

  preview_grid (params, ctx, width, height) {
    var stroke = params.stroke
    var size = params.size
    var color = params.color

    // Box width
    var bw = config.WIDTH
    // Box height
    var bh = config.HEIGHT
    // Padding
    var p = 0

    ctx.clearRect(0, 0, bw, bh)
    //ctx.save();
    ctx.lineWidth = stroke

    for (var x = 0; x <= bw; x += size) {
      ctx.moveTo(0.5 + x + p, p)
      ctx.lineTo(0.5 + x + p, bh + p)
    }

    for (var x = 0; x <= bh; x += size) {
      ctx.moveTo(p, 0.5 + x + p)
      ctx.lineTo(bw + p, 0.5 + x + p)
    }
    ctx.strokeStyle = color
    ctx.stroke()

    //ctx.restore();
  }

  add_grid (params) {
    window.State.save()

    //create borders layer
    this.layer = {
      name: 'Grid',
      type: 'grid',
      render_function: ['grid', 'render'],
      params: { size: params.size, shadow: params.shadow },
      color: params.color,
      x: null,
      y: null,
      width: null,
      height: null,
      is_vector: true
    }

    this.Base_layers.insert(this.layer)
  }
}

export default Tools_grid_class
