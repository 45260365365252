import React from 'react'
import styled from 'styled-components'
import Fab from '@mui/material/Fab'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const ScrollDownFab = ({ onClick, style }) => {
  return (
    <Button
      onClick={onClick}
      color='primary'
      aria-label='scroll to next section'
      style={{
        ...style
      }}
    >
      <KeyboardArrowDownIcon />
    </Button>
  )
}

const Button = styled(Fab)`
  position: absolute;
`

export default ScrollDownFab
