import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import ThemeProvider from './theme/Provider'
import Routes from './routes'
import Notifications from 'components/common/notifications'
import Web3ReactManager from 'components/common/web3-react-manager'

const App = () => {
  return (
    <Web3ReactManager>
      <ThemeProvider>
        <Notifications />
        <Router>
          <Routes />
        </Router>
      </ThemeProvider>
    </Web3ReactManager>
  )
}

export default App
