import _ from 'underscore'

export const startLocateAnimation = (
  timeline,
  locateAnimDuration,
  blurRef,
  fadeRRef,
  fadeGRef,
  fadeBRef,
  currentLocateAnim,
  onComplete
) => {
  if (
    !_.isNull(blurRef.current) &&
    !_.isNull(fadeRRef.current) &&
    !_.isNull(fadeGRef.current) &&
    !_.isNull(fadeBRef.current)
  ) {
    if (currentLocateAnim.current) {
      currentLocateAnim.current.progress(1).kill()
    }
    currentLocateAnim.current = timeline
      .fromTo(
        blurRef.current,
        {
          attr: { stdDeviation: 0 }
        },
        {
          duration: locateAnimDuration / 1000.0 / 2.0,
          repeat: 1,
          yoyo: true,
          attr: { stdDeviation: 4 }
        },
        0
      )
      .fromTo(
        [fadeRRef.current, fadeGRef.current, fadeBRef.current],
        {
          attr: { slope: 1 }
        },
        {
          duration: locateAnimDuration / 1000.0 / 2.0,
          repeat: 1,
          yoyo: true,
          attr: { slope: 0.25 }
        },
        0
      )
      //   .eventCallback('onUpdate', () => {})
      .eventCallback('onComplete', onComplete)
  }
}
export const startPixellateAnimation = (
  timeline,
  animationDuration,
  pixellateGlitchFilter,
  currentPixellateGlitchAnim,
  onComplete
) => {
  if (!_.isNull(pixellateGlitchFilter.current)) {
    if (currentPixellateGlitchAnim.current) {
      currentPixellateGlitchAnim.current.progress(1).kill()
    }

    currentPixellateGlitchAnim.current = timeline
      .fromTo(
        pixellateGlitchFilter.current,
        {
          attr: { scale: 200 }
        },
        {
          duration: animationDuration / 1000.0,

          attr: { scale: 0 }
        },
        0
      )
      .eventCallback('onComplete', function () {
        onComplete && onComplete()
      })
    // .eventCallback('onUpdate', () => {})
  }
}
