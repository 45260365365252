import React from 'react'
import Typography from '@mui/material/Typography'
import Background from './Forms/Background'
import MemberBenefits from '../MemberBenefits'

const MintUpsell = () => {
  return (
    <Background>
      <Typography
        mb={1}
        align="center"
        variant={'h5'}
        sx={{
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: 300,
        }}
      >
        Supporter NFT Benefits:
      </Typography>
      <MemberBenefits />
    </Background>
  )
}

export default MintUpsell
