import { Connector } from '@web3-react/types'
import { gnosisSafe, hooks as gnosisSafeHooks } from './gnosisSafe'
import { metaMask, hooks as metaMaskHooks } from './metamask'
import { network, hooks as networkHooks } from './network'
import {
  walletConnectV2,
  hooks as walletConnectV2Hooks
} from './walletconnectv2'

export const ConnectionType = {
  COINBASE_WALLET: 'COINBASE_WALLET',
  GNOSIS_SAFE: 'GNOSIS_SAFE',
  INJECTED: 'INJECTED',
  NETWORK: 'NETWORK',
  WALLET_CONNECT: 'WALLET_CONNECT'
}

export const PRIORITIZED_CONNECTORS = {
  [ConnectionType.INJECTED]: {
    connector: metaMask,
    hooks: metaMaskHooks,
    type: ConnectionType.INJECTED
  },
  [ConnectionType.WALLET_CONNECT]: {
    connector: walletConnectV2,
    hooks: walletConnectV2Hooks,
    type: ConnectionType.WALLET_CONNECT
  },
  [ConnectionType.GNOSIS_SAFE]: {
    connector: gnosisSafe,
    hooks: gnosisSafeHooks,
    type: ConnectionType.INJECTED
  },
  [ConnectionType.NETWORK]: {
    connector: network,
    hooks: networkHooks,
    type: ConnectionType.INJECTED
  }
}

export function getConnection(c) {
  if (c instanceof Connector) {
    const connection = Object.values(PRIORITIZED_CONNECTORS).find(
      connection => connection.connector === c
    )
    if (!connection) {
      throw Error('unsupported Connector')
    }
    return connection
  } else {
    switch (c) {
      case ConnectionType.INJECTED:
        return PRIORITIZED_CONNECTORS[ConnectionType.INJECTED]
      case ConnectionType.COINBASE_WALLET:
        return PRIORITIZED_CONNECTORS[ConnectionType.COINBASE_WALLET]
      case ConnectionType.WALLET_CONNECT:
        return PRIORITIZED_CONNECTORS[ConnectionType.WALLET_CONNECT]
      case ConnectionType.GNOSIS_SAFE:
        return PRIORITIZED_CONNECTORS[ConnectionType.GNOSIS_SAFE]
      case ConnectionType.NETWORK:
        return PRIORITIZED_CONNECTORS[ConnectionType.NETWORK]
    }
  }
}
