import React from 'react'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import { Typography, Link } from '@mui/material'
const MemberBenefits = () => {
  return (
    <List
      sx={{
        width: '100%',
        listStyleType: 'disc',
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemText
        sx={{
          display: 'list-item',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        primary='Proactive Domain Name Monitoring for your Community'
      />
      <ListItemText
        sx={{
          display: 'list-item',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        primary={
          <Typography>
            Ability to{' '}
            <Link
              href="https://snapshot.org/#/boringsecurity.eth"
              target="_blank"
              rel="noopener"
              underline="hover"
              color="secondary"
            >
              create snapshot proposals
            </Link>{' '}
            to access the{' '}
            <Link
              href="https://zapper.xyz/account/boringsecurityvault.eth"
              target="_blank"
              rel="noopener"
              underline="hover"
              color="secondary"
            >
              Boring Security DAO Funds Pool
            </Link>
          </Typography>
        }
      />
      <ListItemText
        sx={{
          display: 'list-item',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        primary={`Access to draw on your portion of the Supporter Grid (displayed on our front-page)`}
      />
      <ListItemText
        sx={{
          display: 'list-item',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        primary={`Access to a special channel in the Discord with increased access to our founders and contributors`}
      />
      <ListItemText
        sx={{
          display: 'list-item',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        primary={`Discounted products and services`}
      />
      <ListItemText
        sx={{
          display: 'list-item',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
        primary={`Our eternal gratitude ❤️`}
      />
    </List>
  )
}

export default MemberBenefits
