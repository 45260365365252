import React from 'react'
import styled from 'styled-components'

const AlchemyLogo = () => {
  return (
    <a href="#">
      <Image id='badge-button' src='https://static.alchemyapi.io/images/marketing/badge.png' alt='Alchemy Supercharged' />
    </a>
  )
}

const Image = styled.img`
  width: 100%;
  max-width: 240px;
  max-height: 53px;
`

export default AlchemyLogo
