import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { Grid } from '@mui/material'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import Background from './Forms/Background'
import { shortenAddress } from '../../../lib/web3/utils'
import { useMint } from '../../../hooks/grid/use-mint'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import { openInNewTab } from '../../../lib/utils'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useActiveWeb3React } from '../../../hooks/web3'
import { SupportedChain } from '../../../lib/constants'
import useGetTotalGridSupply from '../../../hooks/grid/use-get-total-grid-supply'
import useGetMintCost from '../../../hooks/grid/use-get-mint-cost'
import useGetMaxGridSupply from '../../../hooks/grid/use-get-max-grid-supply'
import useCheckPublicMintOpen from '../../../hooks/grid/use-check-public-mint-open'

const EXPLORER_URLS = {
  [SupportedChain.Ethereum]: 'https://etherscan.io',
  [SupportedChain.Ropsten]: 'https://ropsten.etherscan.io',
  [SupportedChain.Rinkeby]: 'https://rinkeby.etherscan.io',
  [SupportedChain.Goerli]: 'https://goerli.etherscan.io'
}

const MintingForm = ({ limit = 4, openAddressModal, amount, setAmount }) => {
  const { account } = useWeb3React()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const {
    execute: onMint,
    isLoading,
    value,
    error
  } = useMint(amount, account, false)

  const { isLoading: checkingPublicMintOpen, isOpen } = useCheckPublicMintOpen()
  const {
    isLoading: loadingTotalSupply,
    value: totalSupply,
    error: totalSupplyError,
    execute: getTotalSupply
  } = useGetTotalGridSupply(true)
  const {
    isLoading: loadingMaxSupply,
    value: maxSupply,
    error: maxSupplyError,
    execute: getMaxSupply
  } = useGetMaxGridSupply(true)

  const [amountToMint, setAmountToMint] = useState(1)

  const {
    isLoading: loadingCost,
    value: cost,
    error: costError,
    execute: getCost
  } = useGetMintCost(amountToMint, true)

  const [showForm, setShowForm] = useState(true)

  useEffect(() => {
    if (error || value) setShowForm(false)
  }, [error, value])

  const menuItems = Array.from({ length: limit }, (_, i) => (
    <MenuItem value={i + 1}>{i + 1}</MenuItem>
  ))
  return (
    <>
      <Success
        result={value}
        showForm={showForm}
        onOk={() => setShowForm(true)}
      />
      <Error
        error={error}
        showForm={showForm}
        onRetry={() => setShowForm(true)}
      />
      {showForm && (
        <>
          <Background>
            <Typography mb={1} align="center">
              You are connected with
            </Typography>
            <Button
              disabled={isLoading}
              onClick={openAddressModal}
              variant="outlined"
            >
              {shortenAddress(account)}
            </Button>
            <FormControl disabled={isLoading} sx={{ mt: 6 }}>
              <InputLabel id="mint-amount-label">Mint Amount</InputLabel>
              <Select
                labelId="mint-amount-label"
                id="select-mint-amount"
                value={amount}
                label="Mint Amount"
                onChange={event => {
                  setAmountToMint(event.target.value)
                  setAmount(event.target.value)
                }}
              >
                {menuItems}
              </Select>
              <FormHelperText>
                You are limited to {limit} per transaction!
              </FormHelperText>

              <Typography mt={1} align="center">
                Cost: {cost ? `${cost} ETH` : 'Loading...'}
              </Typography>
            </FormControl>
            <FormControl sx={{ my: 2 }}>
              <LoadingButton
                loading={isLoading || checkingPublicMintOpen}
                onClick={onMint}
                variant="contained"
                disabled={
                  parseInt(totalSupply) === parseInt(maxSupply) || !isOpen
                }
              >
                {!isOpen
                  ? 'Minting is closed'
                  : parseInt(totalSupply) === parseInt(maxSupply)
                  ? 'Max supply reached'
                  : 'Mint'}
              </LoadingButton>
              <Typography
                mt={2}
                align="center"
                color={'primary'}
                variant={'h5'}
              >
                {totalSupply
                  ? `${totalSupply}/${maxSupply} MINTED`
                  : 'Loading...'}
              </Typography>
              <Typography
                mt={2}
                align="center"
                color={'secondary'}
                sx={{
                  fontFamily: 'Roboto',
                  fontWeight: 'bold'
                }}
              >
                MINT BONUS: 76800 PAINT
                <br />
                (enough for 3 coverages of your Grid piece!)
              </Typography>
            </FormControl>
          </Background>
        </>
      )}
    </>
  )
}

const Success = ({ result, showForm, onOk }) => {
  const { chainId } = useActiveWeb3React()

  if (!result || showForm) return null

  return (
    <Alert
      severity="success"
      action={
        <Button onClick={onOk} color="inherit" size="small">
          OK
        </Button>
      }
      sx={{ width: '100%' }}
    >
      <AlertTitle>You successfully minted your NFT!</AlertTitle>
      <Button
        aria-label="view-etherscan"
        size="small"
        color="inherit"
        endIcon={<OpenInNewOutlinedIcon fontSize="small" />}
        onClick={() =>
          openInNewTab(`${EXPLORER_URLS[chainId]}/tx/${result.transactionHash}`)
        }
      >
        View tx on Etherscan
      </Button>
    </Alert>
  )
}

const Error = ({ error, showForm, onRetry }) => {
  if (!error || showForm) return null

  const stackTrace =
    error.message &&
    error.message.includes('Transaction has been reverted by the EVM')
      ? error.message
      : null
  const errorMessage = stackTrace ? 'Something went wrong!' : error.message
  return (
    <Alert
      severity="error"
      action={
        <Button onClick={onRetry} color="inherit" size="small">
          RETRY
        </Button>
      }
      sx={{ width: '100%' }}
    >
      <AlertTitle>{errorMessage}</AlertTitle>
      {stackTrace && (
        <TextareaAutosize
          aria-label="error stacktrace"
          minRows={3}
          maxRows={10}
          style={{ width: '100%' }}
        >
          {stackTrace}
        </TextareaAutosize>
      )}
    </Alert>
  )
}

export default MintingForm
